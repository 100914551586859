import { Button, Stack, Typography } from "@mui/material";
import { Chat } from "@stai/types";
import { useTranslation } from "next-i18next";
import { useParams } from "react-router-dom";
import { ErrorView } from "../../Common/views/ErrorView";
import { SecondarySidebarList } from "../../Common/components/sidebar/SecondarySidebarList";
import { useChats } from "../hooks/useChats";
import ChatListItem from "./ChatListItem";
import { SecondarySidebar } from "../../Common/components/sidebar/SecondarySidebar";

export const ChatsList = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<"projectId">();
  const [chats, , error, controller] = useChats(projectId);

  return (
    <SecondarySidebar>
      <Stack px={3} pt={3} pb={1} spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="ah2">{t("chats")}</Typography>
        </Stack>
      </Stack>
      {error && <ErrorView error={error} />}
      <SecondarySidebarList>
        {chats
          .sort((a, b) => (b.updatedAt || b.createdAt).toMillis() - (a.updatedAt || a.createdAt).toMillis())
          .map((chat: Chat) => (
            <ChatListItem key={chat.id} chat={chat} to={chat.id}/>
          ))}
        {controller.hasMore && (
          <Stack mx={2} my={1}>
            <Button onClick={controller.loadMore}>{t("loadMore")}</Button>
          </Stack>
        )}
      </SecondarySidebarList>
    </SecondarySidebar>
  );
};
