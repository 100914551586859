import { create } from "zustand";

interface State {
  isOpen: boolean;
  apiToolConfigId?: string;
  response?: string;
  error?: string;
  open: (
    apiToolConfigId: string,
    response: string | undefined,
    error: string | undefined
  ) => void;
  close: () => void;
}

export const useApiTestToolDialog = create<State>((set) => ({
  isOpen: false,
  open: (apiToolConfigId, response, error) => {
    set({ isOpen: true, apiToolConfigId, response, error });
  },
  close: () => {
    set({
      isOpen: false,
      apiToolConfigId: undefined,
      response: undefined,
      error: undefined,
    });
  },
}));
