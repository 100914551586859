import { Stack } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { ErrorPage } from "../../../Common/views/ErrorPage";
import { LoadingPage } from "../../../Common/views/LoadingPage";
import { useAttachmentConfigs } from "../../hooks/useAttachmentConfigs";
import { useProjectConfig } from "../../hooks/useProjectConfig";
import { useApiToolConfigs } from "../ApiToolPage/hooks/useApiToolConfigs";
import { useKnowledgeConfigs } from "../KnowledgePage/hooks/useKnowledgeConfigs";
import { PhasesForm } from "./PhasesForm";
import { useStaffNotificationConfig } from "../../hooks/useStaffNotificationConfigs";

function showLoadingOrError(loading: boolean, error?: Error) {
  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage error={error} />;
}

export const PhasesPage: FC = () => {
  const { projectId } = useParams<"projectId">();

  const [projectConfig, loadingProject, errorProject] =
    useProjectConfig(projectId);
  const [attachments, loadingAttachment, errorAttachment] =
    useAttachmentConfigs(projectId);
  const [tools, loadingTools, errorTools] = useApiToolConfigs(projectId);
  const [knowledges, loadingKnowledge, errorKnowledge] =
    useKnowledgeConfigs(projectId);
  const [staffNotificationConfig] = useStaffNotificationConfig(projectId);

  //keeping it separate to save type inference for projectConfig and the rest
  if (errorProject || loadingProject)
    return showLoadingOrError(loadingProject, errorProject);
  if (errorTools || loadingTools)
    return showLoadingOrError(loadingTools, errorTools);
  if (errorKnowledge || loadingKnowledge)
    return showLoadingOrError(loadingKnowledge, errorKnowledge);
  if (errorAttachment || loadingAttachment)
    return showLoadingOrError(loadingAttachment, errorAttachment);
  // Stuff notification is optional

  return (
    <Stack padding={3}>
      <PhasesForm
        projectConfig={projectConfig}
        toolConfigs={tools}
        knowledgeConfigs={knowledges}
        attachmentConfigs={attachments}
        staffNotificationConfig={staffNotificationConfig ? [staffNotificationConfig] : []}
      />
    </Stack>
  );
};
