import { Alert, IconButton, ListItemText, Stack, Typography } from "@mui/material";
import { createCallable } from "@stai/common";
import { ConnectApaleoDefinition } from "@stai/types";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { handleError } from "../../../Common/helpers/handleError";
import { LoadingView } from "../../../Common/views/LoadingView";
import { ArrangeHorizontal, Back } from "iconsax-react";
import { t } from "i18next";
import { MAIN_PALETTE } from "../../../Main/consts/MAIN_PALETTE";
import { APALEO_SVG_LOGO_PATH, STAI_SVG_LOGO_PATH_BLACK } from "../../../Common/assets";


const connectApaleoCallable = createCallable(ConnectApaleoDefinition);

export const ApaleoHelloPage: FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [error, setError] = useState<string>();
  const navigate = useNavigate();

  // Get a specific query parameter
  const code = queryParams.get("code");
  const projectId = queryParams.get("state");
  const scope = queryParams.get("scope");

  useEffect(() => {
    if (!code) {
      const msg = "Code is not provided";
      handleError(new Error(msg));
      setError(msg);
      return;
    }

    if (!scope) {
      const msg = "Scope is not provided";
      handleError(new Error(msg));
      setError(msg);
      return;
    }

    if (!projectId) {
      const msg = "Project Id is not provided";
      handleError(new Error(msg));
      setError(msg);
      return;
    }

    connectApaleoCallable({
      authCode: code,
      scopes: scope,
      projectId: projectId,
      redirectUrl: process.env.NEXT_PUBLIC_APALEO_REDIRECT_URL!,
    })
      .then(() => {
        navigate(`/projects/${projectId}/integrations/apaleo`);
      })
      .catch((e: any) => {
        handleError(e);
        setError(e.message);
      });
  }, [code, projectId]);

  const handleBack = () => {
    projectId ? navigate(`/projects/${projectId}/integrations`) : navigate("/");
  };

  return (
    <Stack padding={3} alignItems="center" sx={{backgroundColor: MAIN_PALETTE.background.paper,  height: "100vh",}}>
      <Stack padding={3} direction="row" alignItems="center" spacing={3}>
        <img src={APALEO_SVG_LOGO_PATH} width="250" height="250" />
        <ArrangeHorizontal />
        <img src={STAI_SVG_LOGO_PATH_BLACK} width="250" height="250" />
      </Stack>

      {error && 
        <Stack spacing={3}>
          <Alert severity="error">{error}</Alert>
          <IconButton size="small" onClick={handleBack}>
            <Back />
            <ListItemText primary={t("back")} />
          </IconButton>
        </Stack>
  }
      {!error && (
        <Stack padding={3} direction="column" alignItems="center" spacing={3}>
          <LoadingView />
          <Typography variant="h3">{t("apaleo.hello.title")}</Typography>
        </Stack>
      )}
    </Stack>
  );
};
