import { Stack, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { FC } from "react";

export const UsersPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Stack p={3} spacing={3}>
      <Typography variant="ah2">Users</Typography>
      <Typography variant="body1">To be implemented</Typography>
    </Stack>
  );
};
