import Telegram from "@mui/icons-material/Telegram";
import WhatsApp from "@mui/icons-material/WhatsApp";
import Web from "@mui/icons-material/Web";
import { Chip, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { toLocaleDateTimeString } from "../../Common/helpers/toLocaleDateTimeString";
import { toLocaleDayMonthString } from "../../Common/helpers/toLocaleDayMonthString";
import { Chat, Stay } from "@stai/types";
import { t } from "i18next";
import { CardTravelTwoTone } from "@mui/icons-material";

interface ChatHeaderProps {
    chat: Chat;
    projectId: string;
    stay?: Stay | null;
}

export const ChatHeader: React.FC<ChatHeaderProps> = ({ chat, projectId, stay }) => {
    const navigate = useNavigate();

    return (
        <Stack
            p={2}
            bgcolor="background.paper"
            direction="row"
            position="sticky"
            top={0}
            zIndex={1}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            gap={2}
        >
            <Stack flex={1} gap={1} direction="row">
                {chat.chatProvider === "Whatsapp" && <WhatsApp />}
                {chat.chatProvider === "Telegram" && <Telegram />}
                {chat.chatProvider === "Web" && <Web />}
                <Link target="_blank" to={`https://t.me/id?id=${chat.externalChatId}`}><Typography variant="h3">{chat.guestName || t("anonymous")}</Typography></Link>
                <Chip label={`Messages: ${chat.chatMessageCount || 0}`} />
                {chat.updatedAt && (
                    <Chip label={`Last message: ${toLocaleDateTimeString(chat.updatedAt)}`} />
                )}
            </Stack>
            {stay ? (
                <Stack flex={1}>
                    <Typography
                        onClick={() => navigate(`/projects/${projectId}/guests/${stay.id}`)}
                        sx={{ cursor: "pointer" }}
                        align={"right"}
                        variant={"caption"}
                    >
                        {t("name")} <strong>{stay.name}</strong>. {t("room_number")} <strong>{stay.roomNumber}</strong>
                        <br />
                        {t("check_in_date")} <strong>{toLocaleDayMonthString(stay.checkinDate)}</strong>, {t("check_out_date")}:{" "}
                        <strong>{toLocaleDayMonthString(stay.checkoutDate)}</strong>
                    </Typography>
                </Stack>
            ) : null}
        </Stack>
    );
};