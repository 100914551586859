import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton, Stack } from "@mui/material";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { Control, useFieldArray } from "react-hook-form";
import { useUploadFiles } from "../../../Common/hooks/useUploadFiles";
import { FileInput } from "../../../Common/components/forms/FileInput";
import { FilePreview } from "./FilePreview";
import { FormValues } from "./FormValues";

interface Props {
  index: number;
  control: Control<FormValues>;
  isLoading: boolean;
  projectId: string;
}

export const AttachmentFormFields: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { control, index, isLoading, projectId } = props;

  const attachments = useFieldArray({
    control,
    name: `initialChatMessages.${index}.attachments`,
  });

  const [uploadFiles, , isUploadingFiles] = useUploadFiles({
    projectId: projectId,
    ownerId: projectId,
    ownerType: "project",
    onFileUploaded: (uploadedFile) => {
      switch (uploadedFile.type) {
        case "IMAGE":
          attachments.append({
            type: "IMAGE",
            filePath: uploadedFile.filePath,
            dimensions: uploadedFile.dimensions,
            mimeType: uploadedFile.mimeType,
          });
          break;
        case "AUDIO":
          attachments.append({
            type: "AUDIO",
            filePath: uploadedFile.filePath,
            mimeType: uploadedFile.mimeType,
          });
          break;
      }
    },
  });

  return (
    <Stack pl={8} justifyContent="center" spacing={1.5}>
      {attachments.fields.map((field, i) => (
        <Stack key={field.id} spacing={2} direction="row" alignItems="center">
          <FilePreview filePath={field.filePath} type={field.type} />
          <Stack justifyContent="center">
            <IconButton
              size="large"
              onClick={() => attachments.remove(i)}
              disabled={isLoading}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        </Stack>
      ))}

      <FileInput
        disabled={isUploadingFiles}
        onSelect={uploadFiles}
        accept="image/jpg,image/jpeg,image/png,image/webp,audio/mp3"
        multiple
      >
        <Button variant="text" disabled={isUploadingFiles}>
          {isUploadingFiles && `${t("uploading")}...`}
          {!isUploadingFiles && t("addAttachments")}
        </Button>
      </FileInput>
    </Stack>
  );
};
