import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { useApiTestToolDialog } from "./hooks/useApiTestToolDialog";

export const ApiToolTestDialog: FC = () => {
  const { t } = useTranslation();
  const { isOpen, response, error, close } = useApiTestToolDialog();

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      PaperProps={{
        sx: {
          width: 800,
          maxWidth: "100%",
          height: "30%",
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Stack>{t("apitool.form.testResponse")}</Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack>
          {response && <TextField rows={2} multiline value={response} />}
          {error && (
            <>
              <Typography variant="ah5" color={t("error")}>
                {t("error")}
              </Typography>
              <TextField multiline value={error} />
            </>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button size="large" onClick={close} startIcon={<ArrowBackIcon />}>
          {t("goBack")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
