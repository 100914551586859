import { getDocRef, useValidDocumentData } from "@stai/common";
import { KnowledgeConfig, KnowledgeConfigsCollection } from "@stai/types";

export function useKnowledgeConfig(projectId?: string, knowledgeId?: string) {
  return useValidDocumentData(
    projectId && knowledgeId
      ? getDocRef(KnowledgeConfigsCollection(projectId), knowledgeId)
      : null,
    KnowledgeConfig
  );
}
