import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ChatPage } from "../../Chat/views/ChatPage";
import { ChatsList } from "../../Chat/views/ChatsList";
import { ApiToolList } from "../../Project/views/ApiToolPage/ApiToolList";
import { ApiToolPage } from "../../Project/views/ApiToolPage/ApiToolPage";
import { AttachmentsList } from "../../Project/views/AttachmentsPage/AttachmentsList";
import { AttachmentsPage } from "../../Project/views/AttachmentsPage/AttachmentsPage";
import { GuestPage } from "../../Project/views/GuestsPage/GuestPage";
import { GuestsList } from "../../Project/views/GuestsPage/GuestsList";
import { ApaleoHelloPage } from "../../Project/views/IntegrationsPage/ApaleoHelloPage";
import { ApaleoPage } from "../../Project/views/IntegrationsPage/ApaleoPage";
import { IntegrationsList } from "../../Project/views/IntegrationsPage/IntegrationsList";
import { TelegramPage } from "../../Project/views/IntegrationsPage/TelegramPage";
import { WhatsappPage } from "../../Project/views/IntegrationsPage/WhatsappPage";
import { KnowledgeList } from "../../Project/views/KnowledgePage/KnowledgeList";
import { KnowledgePage } from "../../Project/views/KnowledgePage/KnowledgePage";
import { ManagersPage } from "../../Project/views/ManagersPage/ManagersPage";
import { PhasesPage } from "../../Project/views/PhasesPage/PhasesPage";
import { ProjectCreatePage } from "../../Project/views/ProjectCreatePage";
import { ProjectLayout } from "../../Project/views/ProjectLayout";
import { ProjectPromptPage } from "../../Project/views/ProjectPromtPage/ProjectPromptPage";
import { ProjectSelectPage } from "../../Project/views/ProjectSelectPage";
import { PromptsPage } from "../../Superadmin/views/PromptsPage/PromptsPage";
import { SuperadminLayout } from "../../Superadmin/views/SuperadminLayout";
import { SuperadminProjectSelectPage } from "../../Superadmin/views/SuperadminProjectSelectPage";
import { UsersPage } from "../../Superadmin/views/UsersPage/UsersPage";
import { WhatsappSharedPage } from "../../Project/views/IntegrationsPage/WhatsappSharedPage";
import { TryPage } from "../../Project/views/TryPage/TryPage";
import { ChatIframe } from "../../Project/views/TryPage/ChatIframe";
import { NotifyStaffPage } from "../../Project/views/NotifyStaffPage/NotifyStaffPage";
import { StaffBotPage } from "../../Superadmin/views/StaffNotificationsBot/StaffBotPage";

export const MainRoutes: FC = () => {
  return (
    <>
      <Routes>
        {/* PUBLIC */}
        <Route index element={<Navigate replace to="/projects/select" />} />
        <Route path="projects/select" element={<ProjectSelectPage />} />
        <Route path="projects/create" element={<ProjectCreatePage />} />
        <Route path="hello_apaleo" element={<ApaleoHelloPage />} />
        {/* SUPERADMIN */}
        <Route element={<SuperadminLayout />}>
          <Route path="superadmin/prompts" element={<PromptsPage />} />
          <Route path="superadmin/users" element={<UsersPage />} />
          <Route path="superadmin/projects/select" element={<SuperadminProjectSelectPage />} />
          <Route path="superadmin/whatsapp/shared-integration" element={<WhatsappSharedPage />} />
          <Route path="superadmin/staff-bot" element={<StaffBotPage />} />
        </Route>
        {/* PROJECT */}
        <Route element={<ProjectLayout />}>
          <Route
            path="projects/:projectId"
            element={<ProjectPromptPage />}
          />
          <Route
            path="projects/:projectId/settings"
            element={<ProjectPromptPage />}
          />
          <Route
            path="projects/:projectId/knowledge"
            element={<KnowledgeList />}
          >
            <Route path="new" element={<KnowledgePage />} />
            <Route path=":knowledgeId" element={<KnowledgePage />} />
          </Route>
          <Route
            path="projects/:projectId/attachments"
            element={<AttachmentsList />}
          >
            <Route path="images" element={<AttachmentsPage />} />
            <Route path="documents" element={<AttachmentsPage />} />
            <Route path="videos" element={<AttachmentsPage />} />
            <Route path="audios" element={<AttachmentsPage />} />
          </Route>
          <Route
            path="projects/:projectId/integrations"
            element={<IntegrationsList />}
          >
            <Route path="telegram" element={<TelegramPage />} />
            <Route path="whatsapp" element={<WhatsappPage />} />
            <Route path="apaleo" element={<ApaleoPage />} />
          </Route>
          <Route path="projects/:projectId/tools" element={<ApiToolList />}>
            <Route path="new" element={<ApiToolPage />} />
            <Route path=":knowledgeId" element={<ApiToolPage />} />
          </Route>
          <Route
            path="projects/:projectId/phases"
            element={<PhasesPage />}
          />
          <Route path="projects/:projectId/chats" element={<ChatsList />}>
            <Route path=":chatId" element={<ChatPage />} />
          </Route>
          <Route path="projects/:projectId/guests" element={<GuestsList />}>
            <Route path=":stayId" element={<GuestPage />} />
          </Route>
          <Route
            path="projects/:projectId/managers"
            element={<ManagersPage />}
          />
          <Route
            path="projects/:projectId/staff-notifications"
            element={<NotifyStaffPage
               />}
          ></Route>
          <Route
            path="projects/:projectId/try"
            element={<TryPage />}
          >
            < Route path="anonymous" element={<ChatIframe />} />
            < Route path="stays/:stayId" element={<ChatIframe />} />
            < Route path="chats/:chatId" element={<ChatIframe />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </>
  );
};
