import { Project, ProjectsCollection } from "@stai/types";
import {
  getDocRef,
  useValidDocumentData,
} from "@stai/common";

export function useProject(projectId?: string) {
  return useValidDocumentData(
    projectId ? getDocRef(ProjectsCollection, projectId) : null,
    Project
  );
}
