import { Timestamp } from "@stai/types";
import { DateTime } from "luxon";

export const toLocaleDayMonthString = (timestamp?: Timestamp) => {
  if (!timestamp) return "";
  const date = DateTime.fromJSDate(timestamp.toDate());
  if (DateTime.now().hasSame(date, "day")) return date.toFormat("HH:mm");

  return date.toFormat("MM.dd");
};
