import { Stack } from "@mui/material";
import { FC, useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { ProjectSidebarView } from "./ProjectSidebarView";
import { useIsProjectAdmin, useMyUserRecord } from "@stai/common";
import { ErrorPage } from "../../Common/views/ErrorPage";
import { MAIN_SIDEBAR_WIDTH } from "../../Common/components/sidebar/SideBar";

export const ProjectLayout: FC = () => {
  const { projectId } = useParams<"projectId">();
  const {user} = useMyUserRecord();
  const isProjectAdmin = useIsProjectAdmin(projectId);

  const navigate = useNavigate();
  useEffect(() => {
    if (!projectId || projectId === "undefined") {
      console.error("Project Id is not defined. redirect to project select");
      navigate("projects/select");
    }
  }, [projectId]);

  if(!isProjectAdmin) {
    return <ErrorPage error={new Error(`User ${user?.email} has not access to this project`)} />; 
  }

  return (
    <Stack sx={{ minHeight: "100%" }}>
      <ProjectSidebarView />
      <Stack ml={`${MAIN_SIDEBAR_WIDTH}px`} flex={1}>
        <Outlet />
      </Stack>
    </Stack>
  );
};
