import Add from "@mui/icons-material/Add";
import { Button, ListItemButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ErrorView } from "../../../Common/views/ErrorView";
import { SecondarySidebarList } from "../../../Common/components/sidebar/SecondarySidebarList";
import { useApiToolConfigs } from "./hooks/useApiToolConfigs";
import { SecondarySidebar } from "../../../Common/components/sidebar/SecondarySidebar";

export const ApiToolList = () => {
  const { projectId } = useParams<"projectId">();
  const [tools, , error] = useApiToolConfigs(projectId);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <SecondarySidebar>
      <Stack px={3} pt={3} pb={1} spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="ah2">{t("apiTools")}</Typography>
        </Stack>
      </Stack>
      {error && <ErrorView error={error} />}
      <SecondarySidebarList>
        {tools
          .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())
          .map((tool) => (
            <ListItemButton key={tool.id} to={tool.id} component={NavLink}>
              {tool.name}
            </ListItemButton>
          ))}
        <Stack mx={2} my={5}>
          <Button
            size="large"
            variant="outlined"
            sx={{ alignSelf: "flex-start" }}
            onClick={() => navigate("new")}
            fullWidth
          >
            <Add />
            {t("apitool.page.apiTool")}
          </Button>
        </Stack>
      </SecondarySidebarList>
    </SecondarySidebar>
  );
};
