import { getCollectionRef, usePaginatedCollection } from "@stai/common";
import { Chat, ChatCollection, FirebaseFilter } from "@stai/types";
import { orderBy, query, where } from "firebase/firestore";

export function useChats(projectId?: string, filters?: FirebaseFilter<Chat>[]) {
  if (!projectId) return [];
  return usePaginatedCollection(
    query(
      getCollectionRef(ChatCollection(projectId)),
      ...(filters ? filters?.map((filter) => where(...filter)) : []),
      orderBy("updatedAt", "desc")
    ),
    Chat
  );
}
