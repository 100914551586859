import { Telegram, WhatsApp } from "@mui/icons-material";
import { ListItemButton, Stack, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { SecondarySidebarList } from "../../../Common/components/sidebar/SecondarySidebarList";
import { APALEO_SVG_LOGO_PATH } from "../../../Common/assets";
import { SecondarySidebar } from "../../../Common/components/sidebar/SecondarySidebar";

export const IntegrationsList: FC = () => {
  const { t } = useTranslation();
  return (
    <SecondarySidebar>
      <Stack px={3} pt={3} pb={1} spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="ah2">{t("integrations")}</Typography>
        </Stack>
      </Stack>
      <SecondarySidebarList>
        <Divider sx={{ pb: 3 }}>Messengers</Divider>
        <ListItemButton to={"telegram"} component={NavLink}>
          <Telegram />
          <Typography paddingLeft={1}>{t("telegram")}</Typography>
        </ListItemButton>
        <ListItemButton to={"whatsapp"} component={NavLink}>
          <WhatsApp />
          <Typography paddingLeft={1}>{t("whatsapp")}</Typography>
        </ListItemButton>
        <Divider sx={{ py: 3 }}>PMS</Divider>
        <ListItemButton to={"apaleo"} component={NavLink}>
          <img src={APALEO_SVG_LOGO_PATH} alt="Apaleo" height={20} />
        </ListItemButton>
      </SecondarySidebarList>
    </SecondarySidebar>
  );
};
