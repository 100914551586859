import { Container, ContainerProps } from "@mui/material";
import { FC } from "react";

export const MainContainer: FC<ContainerProps> = ({ sx, ...props }) => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 0,
        ...sx,
      }}
      {...props}
    />
  );
};
