import { Avatar } from "@mui/material";
import { useStorageFile } from "@stai/common";
import { useProject } from "../../Project/hooks/useProject";

const AVATAR_SIZE = 96;

export const ProjectPicture = ({
  projectId,
  size = AVATAR_SIZE,
}: {
  projectId: string;
  size?: number;
}) => {
  const [project] = useProject(projectId);
  const imageFile = useStorageFile(project?.profilePictureFilePath);
  return (
    <Avatar
      src={imageFile.url}
      sx={{
        bgcolor: "primary.main",
        width: size,
        height: size,
      }}
    />
  );
};
