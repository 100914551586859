import { Paper, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { ErrorPage } from "../../../Common/views/ErrorPage";
import { LoadingPage } from "../../../Common/views/LoadingPage";
import { useAppConfig } from "../../hooks/useAppConfig";
import { StuffBotForm } from "./StaffBotForm";


export const StaffBotPage: FC = () => {
  const [appConfig, loading, error] = useAppConfig("default");

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (loading || !appConfig) {
    return <LoadingPage />;
  }

  return (
    <Stack padding={3} spacing={3}>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">Staff Bot Configuration</Typography>
        <Typography variant="body1">
          Configure the staff bot prompt template. It will be concatinated with the prompt set in the project.
          Saving the configuration will update telegram the webhook for the bot.
        </Typography>
      </Stack>
      <StuffBotForm appConfig={appConfig} />
    </Stack>
  );
};
