import { getCollectionRef, usePaginatedCollection } from "@stai/common";
import { ChatMessage, ChatMessageCollection } from "@stai/types";
import { orderBy, query } from "firebase/firestore";

export function useChatMessagesByChatId(projectId?: string, chatId?: string) {
  return usePaginatedCollection(
    chatId
      ? query(
          getCollectionRef(ChatMessageCollection(projectId, chatId)),
          orderBy("createdAt", "desc")
        )
      : null,
    ChatMessage
  );
}
