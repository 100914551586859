import { Stack, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { ErrorView } from "../../../Common/views/ErrorView";
import { LoadingView } from "../../../Common/views/LoadingView";
import { useAppConfig } from "../../hooks/useAppConfig";
import { PrompstForm } from "./PromptsForm";

export const PromptsPage: FC = () => {
  const { t } = useTranslation();
  const [appConfig, isLoading, error] = useAppConfig("default");

  return (
    <Stack p={3} spacing={3}>
      <Typography variant="ah2">{t("app")}</Typography>
      {isLoading && <LoadingView />}
      {error && <ErrorView error={error} />}
      {appConfig && <PrompstForm appConfig={appConfig} />}
    </Stack>
  );
};
