import { KnowledgeDocument } from "@stai/types";
import { z } from "zod";

export const FormValues = z.object({
  title: z.string(),
  description: z.string(),
  // localContentFile: z.string(),
  enabled: z.boolean(),
  asText: z.boolean(),
  textContent: z.string().default(""),
  documents: z.array(KnowledgeDocument).default([]),
  useEmbeddings: z.boolean(),
});

export type FormValues = z.infer<typeof FormValues>;
