import { zodResolver } from "@hookform/resolvers/zod";
import SaveIcon from "@mui/icons-material/Save";
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { createCallable } from "@stai/common";
import {
  ApiToolConfig,
  AttachmentConfig,
  KnowledgeConfig,
  PhaseConfig,
  ProjectConfig,
  SetPhasesDefinition,
  StaffNotificationConfig,
} from "@stai/types";
import { useTranslation } from "next-i18next";
import { FC, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { ControlledCheckbox } from "../../../Common/components/forms/ControlledCheckbox";
import { handleError } from "../../../Common/helpers/handleError";
import { FilePreview } from "../ProjectPromtPage/FilePreview";

export const setPhaseCallable = createCallable(SetPhasesDefinition);

const PhaseRow = z.object({
  id: z.string(),
  all: z.boolean(),
  before: z.boolean(),
  during: z.boolean(),
  after: z.boolean(),
  unknown: z.boolean(),
});
type PhaseRow = z.infer<typeof PhaseRow>;

const FormValues = z.object({
  tools: z.array(PhaseRow),
  knowledges: z.array(PhaseRow),
  attachments: z.array(PhaseRow),
  staff: z.array(PhaseRow), 
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  projectConfig: ProjectConfig;
  toolConfigs: ApiToolConfig[];
  knowledgeConfigs: KnowledgeConfig[];
  attachmentConfigs: AttachmentConfig[];
  staffNotificationConfig: StaffNotificationConfig[];
}

export const PhasesForm: FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { projectConfig, toolConfigs, knowledgeConfigs, attachmentConfigs, staffNotificationConfig } =
    props;

  const { control, handleSubmit, formState, reset, watch } =
    useForm<FormValues>({
      resolver: zodResolver(FormValues),
    });

  useEffect(() => {
    reset({
      knowledges: knowledgeConfigs.map((conf) => ({
        id: conf.id,
        all: conf.activeInPhases.includes("ALL"),
        before: conf.activeInPhases.includes("BEFORE_STAY"),
        during: conf.activeInPhases.includes("DURING_STAY"),
        after: conf.activeInPhases.includes("AFTER_STAY"),
        unknown: conf.activeInPhases.includes("UNKNOWN"),
      })),
      tools: toolConfigs.map((conf) => ({
        id: conf.id,
        all: conf.activeInPhases.includes("ALL"),
        before: conf.activeInPhases.includes("BEFORE_STAY"),
        during: conf.activeInPhases.includes("DURING_STAY"),
        after: conf.activeInPhases.includes("AFTER_STAY"),
        unknown: conf.activeInPhases.includes("UNKNOWN"),
      })),
      attachments: attachmentConfigs.map((conf) => ({
        id: conf.id,
        all: conf.activeInPhases.includes("ALL"),
        before: conf.activeInPhases.includes("BEFORE_STAY"),
        during: conf.activeInPhases.includes("DURING_STAY"),
        after: conf.activeInPhases.includes("AFTER_STAY"),
        unknown: conf.activeInPhases.includes("UNKNOWN"),
      })),
      staff: staffNotificationConfig.map((conf) => ({
        id: "default",
        all: conf.activeInPhases.includes("ALL"),
        before: conf.activeInPhases.includes("BEFORE_STAY"),
        during: conf.activeInPhases.includes("DURING_STAY"),
        after: conf.activeInPhases.includes("AFTER_STAY"),
        unknown: conf.activeInPhases.includes("UNKNOWN"),
      })),
    });
  }, [projectConfig, toolConfigs, attachmentConfigs, knowledgeConfigs]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      console.log(formValues);
      function toPhaseConfig(row: PhaseRow): PhaseConfig {
        return {
          id: row.id,
          activeInPhases: row.all
            ? ["ALL"]
            : [
                row.before ? ["BEFORE_STAY"] : [],
                row.during ? ["DURING_STAY"] : [],
                row.after ? ["AFTER_STAY"] : [],
                row.unknown ? ["UNKNOWN"] : [],
              ].flat(),
        };
      }
      try {
        await setPhaseCallable({
          projectId: projectConfig.id,
          toolsPhases: formValues.tools.map(toPhaseConfig),
          attachmentPhases: formValues.attachments.map(toPhaseConfig),
          knowledgePhases: formValues.knowledges.map(toPhaseConfig),
          staffNotificationsPhases: formValues.staff.map(toPhaseConfig),
        });

        toast.success(t("saved"));
      } catch (error) {
        handleError(error);
      }
    },
    [projectConfig]
  );

  const isLoading = formState.isSubmitting;

  type CheckBoxRowProps = {
    index: number;
    section: "tools" | "knowledges" | "attachments" | "staff";
  };

  const CheckBoxRow = ({ index, section }: CheckBoxRowProps) => {
    const isAllChecked = watch(`${section}.${index}.all`);
    return (
      <>
        <TableCell>
          <ControlledCheckbox
            control={control}
            name={`${section}.${index}.all`}
          />
        </TableCell>
        <TableCell>
          <ControlledCheckbox
            control={control}
            name={`${section}.${index}.before`}
            disabled={isAllChecked}
          />
        </TableCell>
        <TableCell>
          <ControlledCheckbox
            control={control}
            name={`${section}.${index}.during`}
            disabled={isAllChecked}
          />
        </TableCell>
        <TableCell>
          <ControlledCheckbox
            control={control}
            name={`${section}.${index}.after`}
            disabled={isAllChecked}
          />
        </TableCell>
        <TableCell>
          <ControlledCheckbox
            control={control}
            name={`${section}.${index}.unknown`}
            disabled={isAllChecked}
          />
        </TableCell>
      </>
    );
  };

  return (
    <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">{t("phases.header.title")}</Typography>
        <Typography variant="body1">
          {t("phases.header.description")}
        </Typography>
      </Stack>

      <Stack component={Paper} p={2} spacing={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{t("phases.table.header.all")}</TableCell>
              <TableCell>{t("phases.table.header.before")}</TableCell>
              <TableCell>{t("phases.table.header.during")}</TableCell>
              <TableCell>{t("phases.table.header.after")}</TableCell>
              <TableCell>{t("phases.table.header.unknown")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="ah3">{t("phases.knowledge")}</Typography>
              </TableCell>
            </TableRow>
            {knowledgeConfigs.map((toolConfig, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{toolConfig.title}</TableCell>
                  <CheckBoxRow section="knowledges" index={index} />
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell>
                <Typography variant="h3">{t("phases.attachments")}</Typography>
              </TableCell>
            </TableRow>
            {attachmentConfigs.map((toolConfig, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <FilePreview
                      filePath={toolConfig.filePath}
                      type={toolConfig.type}
                      width={70}
                    />
                    <Typography variant="body1" sx={{paddingLeft: 4, maxWidth: 200, 
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                    }}>
                      {toolConfig.context}
                    </Typography>
                  </Stack>
                </TableCell>
                <CheckBoxRow section="attachments" index={index} />
              </TableRow>
            ))}

            <TableRow>
              <TableCell>
                <Typography variant="h3">{t("phases.apiTools")}</Typography>
              </TableCell>
            </TableRow>
            {toolConfigs.map((toolConfig, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="body1">{toolConfig.name}</Typography>
                </TableCell>
                <CheckBoxRow section="tools" index={index} />
              </TableRow>
            ))}
             <TableRow>
            <TableCell>
                <Typography variant="h3">{t("phases.staffNotifications")}</Typography>
              </TableCell>
            </TableRow>
            {([staffNotificationConfig] || []).map((toolConfig, index) => (
            <TableRow>
                  <TableCell>{t("phases.staffNotifications.telegram")}</TableCell>
                  <CheckBoxRow section="staff" index={index} />
            </TableRow>
            ))}

          </TableBody>
        </Table>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button
          size="large"
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          disabled={isLoading}
        >
          {t("save")}
        </Button>
      </Stack>
    </Stack>
  );
};
