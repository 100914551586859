import { Stack, TextField } from "@mui/material";
import { ChatPrompt } from "@stai/types";
import { useTranslation } from "next-i18next";
import { FC } from "react";

interface Props {
  chatPrompt: ChatPrompt;
}

export const ChatPromptDialogTabOutput: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { chatPrompt } = props;

  return (
    <Stack spacing={3}>
      {chatPrompt.tokenUsage && (
        <Stack direction="row" spacing={3}>
          <TextField
            value={chatPrompt.tokenUsage.promptTokens}
            label={t("promptTokens")}
            sx={{ textarea: { fontSize: 14 } }}
            fullWidth
          />
          <TextField
            value={chatPrompt.tokenUsage.completionTokens}
            label={t("completionTokens")}
            sx={{ textarea: { fontSize: 14 } }}
            fullWidth
          />
          <TextField
            value={chatPrompt.tokenUsage.totalTokens}
            label={t("totalTokens")}
            sx={{ textarea: { fontSize: 14 } }}
            fullWidth
          />
        </Stack>
      )}

      <TextField
        value={chatPrompt.responseText}
        label={t("responseText")}
        sx={{ textarea: { fontSize: 14 } }}
        fullWidth
        multiline
      />
    </Stack>
  );
};
