import { getCollectionRef, useValidCollectionData } from "@stai/common";
import {
  Project,
  ProjectsCollection
} from "@stai/types";
import { documentId, query, where } from "firebase/firestore";

export const useUsersProjects = (projectIds: string[]) => {
  return useValidCollectionData(
    projectIds.length > 0 ? query(
      getCollectionRef(ProjectsCollection),
      where(documentId(), "in", projectIds)
    ) : null,
    Project
  );
}
