import { Paper, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { ErrorPage } from "../../../Common/views/ErrorPage";
import { LoadingPage } from "../../../Common/views/LoadingPage";
import { useProjectConfig } from "../../hooks/useProjectConfig";
import { TelegramForm } from "./TelegramForm";
import { useParams } from "react-router-dom";
import { t } from "i18next";


export const TelegramPage: FC = () => {
  const { projectId } = useParams<"projectId">()

  const [projectConfig, loading, error] = useProjectConfig(projectId);

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (loading || !projectConfig) {
    return <LoadingPage />;
  }

  return (
    <Stack padding={3}>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">{t("telegram.page.title")}</Typography>
        <Typography variant="body1">
          {t("telegram.page.description")}
        </Typography>
      </Stack>
      <TelegramForm projectConfig={projectConfig} />
    </Stack>
  );
};
