import { InitialChatMessage } from "@stai/types";
import { LlmType } from "@stai/types/types/LlmType";
import { z } from "zod";

export const FormValues = z.object({
  name: z.string().min(1),
  profilePictureFilePath: z.string(),
  chatPrompt: z.string(),
  initialChatMessages: z.array(
    InitialChatMessage
  ),
  llmType: LlmType,
  timezone: z.string(),
  llmTemperature: z.number().min(0),
});

export type FormValues = z.infer<typeof FormValues>;
