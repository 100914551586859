import { Paper, Stack, Typography } from "@mui/material";
import { KnowledgeConfig } from "@stai/types";
import { nanoid } from "nanoid";
import { useTranslation } from "next-i18next";
import { useParams } from "react-router-dom";
import { ErrorPage } from "../../../Common/views/ErrorPage";
import { LoadingPage } from "../../../Common/views/LoadingPage";
import { KnowledgeForm } from "./KnowledgeForm";
import { useKnowledgeConfig } from "./hooks/useKnowledgeConfig";

const newKnowledge: Partial<KnowledgeConfig> = {
  id: undefined,
  title: "New Knowledge base",
  description: "Use this tool to retreive additional informtion about ...",
  documents: [
    {
      id: nanoid(),
      text: "",
    },
  ],
};

export const KnowledgePage = () => {

  const { t } = useTranslation();
  const { knowledgeId, projectId } = useParams<{
    knowledgeId: string;
    projectId: string;
  }>();

  const [loadedKnowledgeConfig, loading, error] = useKnowledgeConfig(
    projectId,
    knowledgeId
  );
  const knowledgeConfig =
    knowledgeId == undefined ? newKnowledge : loadedKnowledgeConfig;

  if (!knowledgeConfig && error) {
    return <ErrorPage error={error} />;
  }

  if (loading || !knowledgeConfig) {
    return <LoadingPage />;
  }

  return (
    <Stack padding={3} spacing={3}>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">{t("knowledgeBase")}</Typography>
        <Typography variant="body1">{t("knowledgeBaseDescription")}</Typography>
      </Stack>
      <KnowledgeForm projectId={projectId!} knowledgeConfig={knowledgeConfig} />
    </Stack>
  );
};
