import { Stack } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { ErrorPage } from "../../../Common/views/ErrorPage";
import { LoadingPage } from "../../../Common/views/LoadingPage";
import { useProjectInvitations } from "../../hooks/useProjectInvitations";
import { useProjectUsers } from "../../hooks/useProjectUsers";
import { ManagersForm } from "./ManagersView";

export const ManagersPage: FC = () => {
  const { projectId } = useParams<"projectId">();
  const [invitations, isLoadingInvitations, invitationsError] =
    useProjectInvitations(projectId);

  const [users, isLoadingUsers, usersError] = useProjectUsers(projectId);

  if (usersError) {
    return <ErrorPage error={usersError} />;
  }

  if (invitationsError) {
    return <ErrorPage error={invitationsError} />;
  }

  if (
    !projectId ||
    isLoadingUsers ||
    isLoadingInvitations ||
    !invitations ||
    !users
  ) {
    return <LoadingPage />;
  }

  return (
    <Stack padding={3}>
      <ManagersForm
        projectId={projectId}
        invitations={invitations}
        users={users}
      />
    </Stack>
  );
};
