import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { FirebaseAuthSignInPage } from "../../Common/components/auth/FirebaseAuthSignInPage";

export const MainLoggedOutRoutes: FC = () => {
  return (
    <Routes>
      <Route path="appaleo_connect" element={<FirebaseAuthSignInPage />} />
      <Route index element={<FirebaseAuthSignInPage />} />
      <Route path="sign-in" element={<FirebaseAuthSignInPage />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};
