import { IconButton, ListItemText, Stack } from "@mui/material";
import { auth } from "@stai/common";
import { t } from "i18next";
import { Back, Logout, Folder} from "iconsax-react";
import { FC } from "react";
import { MainContainer } from "../../Main/views/MainContainer";
import { ErrorView } from "./ErrorView";
import { useNavigate } from "react-router-dom";

interface Props {
  error: Error;
}

export const ErrorPage: FC<Props> = (props) => {
  const { error } = props;
  const navigate = useNavigate();
  function handleLogout() {
    void auth.signOut();
  }

  function handleSelectProject() {
    navigate("/projects/select");
  }

  return (
    <MainContainer
      sx={{
        alignItems: "center",
      }}
    >
      <ErrorView error={error} />
      <Stack
        p={5}
        display="flex"
        direction="column"
        minHeight="100%"
        justifyContent="flex-end"
        alignItems="center"
      >
        <IconButton size="small" onClick={handleSelectProject}>
          <Folder />
          <ListItemText primary={t("projects")} />
        </IconButton>

        <IconButton size="small" onClick={handleLogout}>
          <Logout />
          <ListItemText primary={t("logout")} />
        </IconButton>
      </Stack>
    </MainContainer>
  );
};
