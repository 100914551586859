import { zodResolver } from "@hookform/resolvers/zod";
import SendIcon from "@mui/icons-material/Send";
import { IconButton, Stack, styled } from "@mui/material";
import { createCallable, useMyUserRecord } from "@stai/common";
import { CreateMessageFromManagerDefinition } from "@stai/types";
import { useTranslation } from "next-i18next";
import { FC, useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledTextField } from "../../Common/components/forms/ControlledTextField";

const sendMessageFromManagerCallable = createCallable(
  CreateMessageFromManagerDefinition
);

const FormValues = z.object({
  text: z.string(),
});

export type FormValues = z.infer<typeof FormValues>;

interface Props {
  projectId: string;
  chatId: string;
}

export const ChatInputView: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { projectId, chatId } = props;
  const userId = useMyUserRecord((state) => state.user?.id);
  const inputRef = useRef<HTMLInputElement>();
  const { control, handleSubmit, setValue } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: { text: "" },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const text = formValues.text.trim();

        if (!text) return;
        if (!projectId) return;
        if (!userId) return;

        setValue("text", "");
        inputRef.current?.focus();
        // useLocalChatMessages.getState().add({
        //   id: localId,
        //   text,
        //   senderType: "USER",
        //   guestId: userId || "USER",
        //   localId,
        //   createdAt: Timestamp.now(),
        // });

        // const chat = await useAsyncChat.getState().getChat();

        await sendMessageFromManagerCallable({
          projectId,
          chatId,
          managerId: userId,
          text,
        });
      } catch (error) {
        console.error(error);
        handleError(error);
      }
    },
    [setValue, userId, projectId]
  );

  return (
    <>
      <Stack position="relative" bottom={0} right={0} zIndex={5}>
        <Stack
          bottom={0}
          left={0}
          // right={0}
          position="relative"
          zIndex={1}
          bgcolor="grey.700"
          direction="row"
          alignItems="center"
          p={1}
          width="100%"
          spacing={1}
        >
          <Stack
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            direction="row"
            bgcolor="background.default"
            borderRadius={100}
            flex={1}
          >
            <ControlledTextField
              name="text"
              control={control}
              inputRef={inputRef}
              sx={{
                "& fieldset": { border: "none" },
              }}
              placeholder={t("chat")}
              autoComplete="off"
              fullWidth
            />
            <Stack justifyContent="center" pr={0.5}>
              <SIconButton type="submit">
                <SendIcon />
              </SIconButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

const SIconButton = styled(IconButton)(({ theme }) => ({
  width: 48,
  height: 48,
  borderColor: theme.palette.grey[400],
  borderStyle: "solid",
  backgroundColor: theme.palette.grey[500],

  "@media(hover: hover)": {
    "&:hover": {
      backgroundColor: theme.palette.grey[400],
    },
  },
}));
