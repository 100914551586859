import { LinkProps } from "@mui/material/Link";
import { createTheme, lighten } from "@mui/material/styles";
import { Inter } from "next/font/google";
import { CSSProperties, forwardRef } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { MAIN_PALETTE } from "./MAIN_PALETTE";

const GEOLOGICA = Inter({
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  subsets: ["latin-ext"],
});

const COMFORTAA = Inter({
  weight: ["300", "700"],
  subsets: ["latin-ext"],
});

const defaultFontConfig = {
  fontFamily: COMFORTAA,
  fontSize: 14,
  fontWeight: 200,
  lineHeight: 1.35,
};

declare module "@mui/material/styles" {
  interface TypographyVariants {
    ah1: CSSProperties;
    ah2: CSSProperties;
    ah3: CSSProperties;
    ah5: CSSProperties;
    ah6: CSSProperties;
  }

  interface TypographyVariantsOptions {
    ah1: CSSProperties;
    ah2: CSSProperties;
    ah3: CSSProperties;
    ah5: CSSProperties;
    ah6: CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    ah1: true;
    ah2: true;
    ah3: true;
    ah5: true;
    ah6: true;
  }
}

/**
 * @see https://mui.com/material-ui/guides/routing/#global-theme-link
 */
const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  return <RouterLink ref={ref} to={href} {...other} />;
});

LinkBehavior.displayName = "LinkBehavior";

export const MAIN_THEME = createTheme({
  palette: MAIN_PALETTE,
  typography: {
    allVariants: {
      fontFamily: GEOLOGICA.style.fontFamily,
    },
    ah1: {
      fontFamily: GEOLOGICA.style.fontFamily,
      fontSize: 40,
      fontWeight: 900,
      lineHeight: "110%",
      letterSpacing: "-0.3px",
    },
    ah2: {
      fontFamily: GEOLOGICA.style.fontFamily,
      fontSize: 30,
      fontWeight: 900,
      lineHeight: "110%",
      letterSpacing: "-0.3px",
    },
    ah3: {
      fontFamily: GEOLOGICA.style.fontFamily,
      fontSize: 20,
      fontWeight: 900,
      lineHeight: "130%",
      letterSpacing: "-0.3px",
    },
    ah5: {
      fontFamily: COMFORTAA.style.fontFamily,
      fontSize: 16,
      fontWeight: 700,
      lineHeight: "130%",
      textTransform: "uppercase",
    },
    ah6: {
      fontFamily: GEOLOGICA.style.fontFamily,
      fontSize: 14,
      fontWeight: 900,
      lineHeight: "130%",
    },
    h3: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: "125%",
    },
    h4: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: "140%",
    },
    h5: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: "150%",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: "100%",
        },
        body: {
          height: "100%",
          backgroundColor: MAIN_PALETTE.background.default,
          ...defaultFontConfig,
        },
        "#__next": {
          height: "100%",
        },
      },
    },
    // credits: https://mui.com/material-ui/guides/routing/#global-theme-link
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },

    MuiButtonBase: {
      defaultProps: {
        style: {
          borderRadius: "30px",
        },
        disableRipple: false,
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: "outlined", color: "secondary" },
          style: {
            color: MAIN_PALETTE.common.black,
            borderColor: MAIN_PALETTE.common.black,
            backgroundColor: MAIN_PALETTE.common.white,
            "&:hover": {
              borderColor: MAIN_PALETTE.common.black,
              backgroundColor: lighten(MAIN_PALETTE.primary.main, 0.95),
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          whiteSpace: "nowrap",
          "&&": {
            textTransform: "inherit",
          },
        },
        sizeLarge: {
          height: 56,
          fontWeight: "bold",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: `12px 16px`,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: MAIN_PALETTE.common.white,
          borderRadius: "20px !important",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          //marginLeft:"20px"
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: "18px",
        },
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: "wave",
      },
    },
  },
});
