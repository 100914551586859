import { Stack } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { ErrorPage } from "../../../Common/views/ErrorPage";
import { LoadingPage } from "../../../Common/views/LoadingPage";
import { useProject } from "../../hooks/useProject";
import { useProjectConfig } from "../../hooks/useProjectConfig";
import { ProjectPromptForm } from "./ProjectPromptForm";

export const ProjectPromptPage: FC = () => {
  const { projectId } = useParams<"projectId">();
  const [projectConfig, loadingConfig, configError] =
    useProjectConfig(projectId);
  const [project, loadingProject, projectError] = useProject(projectId);

  if (loadingProject || loadingConfig) {
    return <LoadingPage />;
  }

  if (configError) {
    return <ErrorPage error={configError} />;
  }
  if (projectError) {
    return <ErrorPage error={projectError} />;
  }
  if (!projectConfig || !project) {
    return <ErrorPage error={new Error("Project not found")} />;
  }

  return (
    <Stack padding={3}>
      <ProjectPromptForm project={project} projectConfig={projectConfig} />
    </Stack>
  );
};
