import { zodResolver } from "@hookform/resolvers/zod";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Divider, Paper, Stack } from "@mui/material";
import { createCallable } from "@stai/common";
import {
  ApaleoIntegration,
  DeleteApaleoIntegrationDefinition,
  UpdateApaleoIntegrationDefinition,
} from "@stai/types";
import { useTranslation } from "next-i18next";
import { FC, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { handleError } from "../../../Common/helpers/handleError";
import { ControlledTextField } from "../../../Common/components/forms/ControlledTextField";

const updateApaleoIntegrationCallable = createCallable(
  UpdateApaleoIntegrationDefinition
);


const FormValues = z.object({
  idToken: z.string(),
  accessToken: z.string(),
  propertyId: z.string(),
  webhookUrl: z.string(),
  subscriptionId: z.string(),
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  projectId: string;
  apaleoIntegration?: ApaleoIntegration;
}

export const ApaleoForm: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { apaleoIntegration, projectId } = props;

  const defaultValues = {
    idToken: apaleoIntegration?.idToken || "",
    accessToken: apaleoIntegration?.accessToken || "",
    webhookUrl: apaleoIntegration?.webhookUrl || "",
    subscriptionId: apaleoIntegration?.subscriptionId || "",
    propertyId: apaleoIntegration?.propertyId || "",
  };

  const { control, handleSubmit, formState, reset } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [apaleoIntegration]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await updateApaleoIntegrationCallable({
          projectId: projectId,
          propertyId: formValues.propertyId,
        });

        toast.success(t("saved"));
      } catch (error) {
        handleError(error);
      }
    },
    [apaleoIntegration]
  );

  const isLoading = formState.isSubmitting;

  return (
    <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack component={Paper} direction="row" p={2} spacing={3}>
        <ControlledTextField
          control={control}
          label={t("apaleo.connect.property_id")}
          name="propertyId"
          autoComplete="off"
          fullWidth
        />
        <Button
          size="large"
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          disabled={isLoading}
        >
          {t("save")}
        </Button>
      </Stack>
    </Stack>
  );
};
