import { PaletteOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface TypeBackground {
    gradient: string;
  }
}

export const MAIN_PALETTE = {
  mode: "light",
  background: {
    default: "#FFFFFF",
    paper: "#F6F6F6",
  },
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
  divider: "#EEEEEE",
  text: {
    primary: "#162E52",
    secondary: "#596576",
  },
  primary: {
    main: "#49E374",
  },
  secondary: {
    main: "#FAD81C",
  },
  success: {
    main: "#44AC5B",
    contrastText: "#FFFFFF",
  },
} satisfies PaletteOptions;


export const COLORS = {
  whatsapp: "#25D366",
  telegram: "#229ED9",
}