"use client";
import { NoSsr } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { FC } from "react";
import { Toaster } from "react-hot-toast";
import { MAIN_THEME } from "../consts/MAIN_THEME";
import { MainEmotionCacheProvider } from "./MainEmotionCache";
import { MainInitView } from "./MainInitView";
import { MainRouter } from "./MainRouter";

export const MainProvider: FC = () => {
  return (
    <NoSsr>
      <MainEmotionCacheProvider options={{ key: "mui" }}>
        <ThemeProvider theme={MAIN_THEME}>
          <CssBaseline />
          <MainRouter>
            <MainInitView />
          </MainRouter>
          <Toaster />
        </ThemeProvider>
      </MainEmotionCacheProvider>
    </NoSsr>
  );
};
