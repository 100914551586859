import {
  Button,
  ListItem,
  ListItemButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Stay } from "@stai/types";
import { DateTime } from "luxon";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { SecondarySidebar } from "../../../Common/components/sidebar/SecondarySidebar";
import { SecondarySidebarList } from "../../../Common/components/sidebar/SecondarySidebarList";
import { ErrorView } from "../../../Common/views/ErrorView";
import { useStays } from "../../hooks/useStays";

function stayDatesStr(stay: Stay) {
  return stay.checkinDate && stay.checkoutDate
    ? `${DateTime.fromJSDate(stay.checkinDate.toDate()).toFormat(
        "MM.dd.yyyy"
      )} - ${DateTime.fromJSDate(stay.checkoutDate.toDate()).toFormat(
        "MM.dd.yyyy"
      )}`
    : "";
}

export const GuestsList = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<"projectId">();

  const [searchQuery, setSearchQuery] = useState("");
  const [stays, , error, controller] = useStays(projectId, [
    ["name", ">=", searchQuery],
  ]);

  return (
    <SecondarySidebar>
      <Stack px={3} pt={3} pb={1} spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="ah2">{t("guests")}</Typography>
        </Stack>
      </Stack>
      {error && <ErrorView error={error} />}
      <SecondarySidebarList>
        <ListItem>
          <TextField
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            label={"Search"}
            sx={{
              width: "100%",
            }}
          />
        </ListItem>
        {stays
          .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())
          .map((stay) => (
            <ListItemButton key={stay.id} to={stay.id} component={NavLink} className="chatsList">
              <Stack direction="column" alignItems="flex-start" width="100%">
                {stay.roomNumber ? (
                  <Typography variant="body1">
                    {t("room_number")}: <strong>{stay.roomNumber}</strong>
                  </Typography>
                ) : null}
                <Typography variant="body2">{stay.name}</Typography>
                <Typography variant="subtitle2" color="grey">
                  {stayDatesStr(stay)}
                </Typography>
              </Stack>
            </ListItemButton>
          ))}
        {controller.hasMore && (
          <Stack mx={2} my={1}>
            <Button onClick={controller.loadMore}>{t("loadMore")}</Button>
          </Stack>
        )}
      </SecondarySidebarList>
    </SecondarySidebar>
  );
};
