import type { FirebaseFunctionDefinition } from "@stai/types";
import { httpsCallable, HttpsCallableOptions } from "firebase/functions";
import { functions } from "..";
import { CallableError } from "./types/CallableError";

const SERVER_NAME = "app";

interface Args<RequestArgs, ResponseArgs>
  extends FirebaseFunctionDefinition<RequestArgs, ResponseArgs> {
  options?: HttpsCallableOptions;
}

function omitUndefinedParams<T>(obj?: T): Partial<T>| undefined {
  if (!obj) {
    return undefined;
  }
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => value !== undefined)
  ) as Partial<T>;
}

export function createCallable<RequestArgs, ResponseArgs>(
  args: Args<RequestArgs, ResponseArgs>
) {
  const callable = httpsCallable(
    functions,
    `${SERVER_NAME}${args.path}`,
    args.options
  );

  return async (requestArgs: RequestArgs): Promise<ResponseArgs> => {
    try {
      const response = await callable(omitUndefinedParams(args.getReq?.(requestArgs))); 
      return args.getRes?.(response.data) as ResponseArgs;
    } catch (error) {
      const callableError = new CallableError({
        path: args.path,
        error: error as Error,
      });

      // TODO: handle error more gracefully
      console.error("callable error", callableError);

      throw callableError;
    }
  };
}
