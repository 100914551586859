import { ProjectConfig, ProjectConfigsCollection } from "@stai/types";
import { getDocRef, useValidDocumentData } from "@stai/common";

export function useProjectConfig(projectConfigId?: string) {
  return useValidDocumentData(
    projectConfigId
      ? getDocRef(ProjectConfigsCollection, projectConfigId)
      : null,
    ProjectConfig
  );
}
