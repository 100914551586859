import { getCollectionRef, useValidCollectionData } from "@stai/common";
import { User, UsersCollection } from "@stai/types";
import { query, where } from "firebase/firestore";

export function useProjectUsers(projectId?: string) {
  return useValidCollectionData(
    projectId
      ? query(
          getCollectionRef(UsersCollection),
          where("managedProjectIds", "array-contains", projectId)
        )
      : null,
    User
  );
}
