import { Paper, Stack, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { FileType } from "@stai/types";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { ErrorPage } from "../../../Common/views/ErrorPage";
import { LoadingPage } from "../../../Common/views/LoadingPage";
import { useAttachmentConfigs } from "../../hooks/useAttachmentConfigs";
import { AttachmentsConfigList } from "./AttachmentConfigsList";

export const AttachmentsPage: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<"projectId">();

  const urlPath = window.location.pathname;
  const pathSegments = urlPath.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];

  const fileTypes: Record<string, FileType> = {
    videos: FileType.Values.VIDEO,
    audios: FileType.Values.AUDIO,
    documents: FileType.Values.DOCUMENT,
    images: FileType.Values.IMAGE,
  } as const;

  const [attachmentConfigs, attachmentsLoading, attachmentsError] =
    useAttachmentConfigs(
      projectId,
      fileTypes[lastSegment] || ("IMAGE" as const)
    );

  if (attachmentsError) {
    return <ErrorPage error={attachmentsError} />;
  }
  if (!projectId || attachmentsLoading) {
    return <LoadingPage />;
  }

  return (
    <Stack padding={2} spacing={3}>
      <Stack component={Paper} p={2}>
        <Typography variant="ah3">{t("attachments.title")}</Typography>
        <Typography variant="body1">
          {t("attachments.description")}
        </Typography>
      </Stack>
      <AttachmentsConfigList
        projectId={projectId}
        attachmentConfigs={attachmentConfigs}
      />
    </Stack>
  );
};
