import { GetStaticPaths, GetStaticProps } from "next";
import { MainProvider } from "../Main/views/MainProvider";

export default MainProvider;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
    //   ...(locale ? await serverSideTranslations(locale, ["translation"]) : {}),
    },
  };
};

export const getStaticPaths: GetStaticPaths = () => {
  return {
    paths: [
      {
        params: {
          slug: [] as string[],
        },
      },
    ],
    fallback: "blocking",
  };
};
