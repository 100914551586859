import { Stack } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import { MAIN_SECONDARY_SIDEBAR_WIDTH } from "../../../Main/consts/MAIN_SECONDARY_SIDEBAR_WIDTH";
import { MAIN_SIDEBAR_WIDTH } from "./SideBar";

type Props = {
  width?: number,
}

export const SecondarySidebar: FC<PropsWithChildren<Props>> = (props) => {
  const { children, width = MAIN_SECONDARY_SIDEBAR_WIDTH } = props;

  return (
    <Stack direction="row" flex={1} pl={`${width}px`}>
      <Stack
        position="fixed"
        top={0}
        bottom={0}
        left={MAIN_SIDEBAR_WIDTH}
        width={width}
        borderRight={1}
        borderColor="grey.500"
        overflow="scroll"
      >
        {children}
      </Stack>
      <Stack flex={1}>
        <Outlet />
      </Stack>
    </Stack>
  );
};
