import { Chip, Stack } from "@mui/material";
import { Chat } from "@stai/types";
import { DateTime } from "luxon";
import { useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useChats } from "../../../Chat/hooks/useChats";
import { MainContainer } from "../../../Main/views/MainContainer";
import ChatListItem from "../../../Chat/views/ChatListItem";
import { SecondarySidebarList } from "../../../Common/components/sidebar/SecondarySidebarList";

export const GuestPage = () => {
  const { stayId, projectId } = useParams<{
    stayId: string;
    projectId: string;
  }>();
  
  const scrollRef = useRef<HTMLDivElement>(null);
  // const [stay, staysLoading, loadingError] = useStay(projectId, stayId);
  const [chats, chatsLoading, chatError, chatsController] = useChats(
    projectId,
    [["stayId", "==", stayId]]
  );

  return (
    <>
      <Stack
        p={2}
        bgcolor="background.paper"
        direction="row"
        spacing={1}
        position="sticky"
        top={0}
        zIndex={1}
      >
        <Chip label={`Chats: ${chats.length}`} />
      </Stack>
      <MainContainer>
        <Stack p={3} spacing={3} flex={1}>
          {/* {chatsController.hasMore && (
            <Stack mx={2} my={1}>
              <Button onClick={controller.loadMore}>Load more</Button>
            </Stack>
          )} */}
          <SecondarySidebarList>
          {chats
            .sort((a, b) => a.createdAt.toMillis() - b.createdAt.toMillis())
            .map((chat: Chat) => (
              <ChatListItem key={chat.id} chat={chat} to={`/projects/${projectId}/chats/${chat.id}`}/>
            ))}
            </SecondarySidebarList>
          <Stack ref={scrollRef} />
        </Stack>
      </MainContainer>
    </>
  );
};
