import { styled } from "@mui/material";
import Drawer from "@mui/material/Drawer";


export const MAIN_SIDEBAR_WIDTH = 200;

export const SideBar = styled(Drawer)(() => ({
    width: `${MAIN_SIDEBAR_WIDTH}px`,
    backgroundColor: "#141414",
  
    ".MuiSvgIcon-root": {
      fill: "white",
    },
    ".MuiListItemIcon-root svg path": {
      stroke: "white",
    },
    ".active .MuiSvgIcon-root": {
      fill: "#49E374",
    },
    ".active .MuiListItemIcon-root svg path": {
      stroke: "#49E374",
    },
    ".MuiDrawer-paper": {
      backgroundColor: "#141414",
      borderRadius: "0px",
      color: "white",
      border: "none",
      width: `${MAIN_SIDEBAR_WIDTH}px`,
      boxSizing: "border-box",
      padding: "5px",
    },
    ".MuiButtonBase-root:hover": {
      backgroundColor: "white",
      color: "#141414",
      ".MuiSvgIcon-root": {
        fill: "#141414",
      },
      ".MuiListItemIcon-root svg path": {
        stroke: "#141414",
      },
    },
    ".MuiButtonBase-root:hover.active": {
      ".MuiListItemIcon-root svg path": {
        stroke: "#49E374",
      },
    },
  }));
  