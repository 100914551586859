import { Paper, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { ErrorPage } from "../../../Common/views/ErrorPage";
import { LoadingPage } from "../../../Common/views/LoadingPage";
import { useStaffNotificationConfig } from "../../hooks/useStaffNotificationConfigs";
import { NotifyStaffForm } from "./NotifyStaffForm";

export const NotifyStaffPage: FC = () => {
  const { projectId } = useParams<"projectId">();
  const [staffNotificationConfig, isLoadingStuffNotificationConfigs, configError] = useStaffNotificationConfig(projectId);

  if (!projectId) {
    return <ErrorPage error={new Error("Project not found")} />;
  }

  if (
    isLoadingStuffNotificationConfigs
  ) {
    return <LoadingPage />;
  }

  const defaultStuffConfig = {
    enabled: true,
    telegramHandles: [],
    botGuidance: "",
    activeInPhases: ["ALL"],
  };

  return (
    <Stack padding={3} spacing={3}>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">{t("staff.page.title")}</Typography>
        <Typography variant="body1">{t("staff.page.description")}</Typography>
      </Stack>
      <NotifyStaffForm
        projectId={projectId}
        staffNotificationConfig={staffNotificationConfig || defaultStuffConfig}
      />
    </Stack>
  );
};
