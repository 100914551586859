import { Alert, Divider, Paper, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { LoadingPage } from "../../../Common/views/LoadingPage";
import { useApaleoIntegration } from "../../hooks/useApaleoIntegration";
import { ApaleoConnectForm } from "./ApaleoConnectForm";
import { ApaleoForm } from "./ApaleoForm";

export const ApaleoPage: FC = () => {
  const { projectId } = useParams<"projectId">();

  let [apaleoIntegration, loadingIntegration, errorIntegration] =
    useApaleoIntegration(projectId);
  if (loadingIntegration) return <LoadingPage />;

  return (
    <Stack padding={3} spacing={3}>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">{t("apaleo.connect.title")}</Typography>
        <Typography variant="body1">
          {t("apaleo.connect.description")}
        </Typography>
      </Stack>

      {apaleoIntegration?.error && (
        <Stack component={Paper} p={2} spacing={3}>
          <Typography variant="ah2">{t("apaleo.connect.error")}</Typography>
          <Alert severity="error">
            <Typography variant="body1">{apaleoIntegration.error}</Typography>
          </Alert>
        </Stack>
      )}

      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">{t("apaleo.connect.step1.title")}</Typography>
        <Typography variant="body1">
          {t("apaleo.connect.step1.description")}
        </Typography>
        <ApaleoConnectForm
          apaleoIntegration={apaleoIntegration}
          projectId={projectId!}
        />

        {apaleoIntegration?.accessToken && (
          <>
            <Divider />
            <Alert severity="success">
              {t("apaleo.connect.step1.success")}
            </Alert>
          </>
        )}
      </Stack>

      {apaleoIntegration?.accessToken && (
        <Stack component={Paper} p={2} spacing={3}>
          <Typography variant="ah3">
            {t("apaleo.connect.step2.title")}
          </Typography>
          <Typography variant="body1">
            {t("apaleo.connect.step2.description")}
          </Typography>
          <ApaleoForm
            apaleoIntegration={apaleoIntegration}
            projectId={projectId!}
          />
          {apaleoIntegration?.webhookUrl &&
            apaleoIntegration?.subscriptionId && (
              <>
                <Divider />
                <Alert severity="success">
                  {t("apaleo.connect.step2.success")}
                </Alert>
              </>
            )}
        </Stack>
      )}
    </Stack>
  );
};
