import { Button, Paper, Stack } from "@mui/material";
import { AttachmentConfig } from "@stai/types";
import { FC, useState } from "react";
import { FileInput } from "../../../Common/components/forms/FileInput";
import { useUploadFiles } from "../../../Common/hooks/useUploadFiles";
import { AttachmentConfigForm } from "./AttachmentConfigForm";

interface Props {
  projectId: string;
  attachmentConfigs: AttachmentConfig[];
}

export const AttachmentsConfigList: FC<Props> = (props) => {
  const { projectId, attachmentConfigs } = props;

  const [uploadedAttacmentConfig, setUploadedAttacmentConfig] =
    useState<Partial<AttachmentConfig> | null>(null);

  const [uploadFiles, , isUploadingFiles] = useUploadFiles({
    projectId: projectId,
    ownerId: projectId,
    ownerType: "project",
    onFileUploaded: (uploadedFile, originalFile) => {
      setUploadedAttacmentConfig({
        type: uploadedFile.type,
        originalFileName: originalFile?.name,
        filePath: uploadedFile.filePath,
        mimeType: uploadedFile.mimeType,
        ...(uploadedFile.dimensions
          ? { dimensions: uploadedFile.dimensions }
          : {}),
        context: "",
      });
    },
  });

  const onCreateCanceled = () => {
    setUploadedAttacmentConfig(null);
  };

  return (
    <Stack spacing={3}>
      <Stack component={Paper} p={2} spacing={3}>
        <Stack spacing={1.5}>
          {attachmentConfigs.map((attachment) => (
            <AttachmentConfigForm
              projectId={projectId}
              attachmentConfig={attachment}
              key={attachment.id}
            />
          ))}
          {uploadedAttacmentConfig && (
            <AttachmentConfigForm
              projectId={projectId}
              attachmentConfig={uploadedAttacmentConfig}
              cancelCreateNew={onCreateCanceled}
            />
          )}
          {!uploadedAttacmentConfig && (
            <>
              <FileInput
                disabled={isUploadingFiles}
                onSelect={uploadFiles}
                accept="image/jpg,image/jpeg,image/png,image/webp,audio/mp3,audio/wav,video/mp4,video/webm,application/pdf"
                multiple
              >
                <Button variant="text" disabled={isUploadingFiles}>
                  {isUploadingFiles && "Uploading... "}
                  {!isUploadingFiles && "Add File"}
                </Button>
              </FileInput>
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
