import { zodResolver } from "@hookform/resolvers/zod";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Chip,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { createCallable } from "@stai/common";
import {
  LLM_TYPES,
  PROMPT_CONTEXT_KEYS,
  Project,
  ProjectConfig,
  UpdateProjectDefinition,
} from "@stai/types";
import { DateTime } from "luxon";
import { useTranslation } from "next-i18next";
import { FC, Fragment, useCallback } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { ControlledSelect } from "../../../Common/components/forms/ControlledSelect";
import { ControlledTextField } from "../../../Common/components/forms/ControlledTextField";
import { FileInput } from "../../../Common/components/forms/FileInput";
import { handleError } from "../../../Common/helpers/handleError";
import { useUploadFiles } from "../../../Common/hooks/useUploadFiles";
import { TIMEZONES } from "../../types/TIMEZONE";
import { AttachmentFormFields } from "./AttachmentFormFields";
import { FilePreview } from "./FilePreview";
import { FormValues } from "./FormValues";

export const adminUpdateProjectCallable = createCallable(
  UpdateProjectDefinition
);
interface Props {
  project: Project;
  projectConfig: ProjectConfig;
}

export const ProjectPromptForm: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { project, projectConfig } = props;

  const { control, handleSubmit, formState, watch, setValue } =
    useForm<FormValues>({
      resolver: zodResolver(FormValues),
      defaultValues: {
        name: project.name,
        profilePictureFilePath: project.profilePictureFilePath,
        chatPrompt: projectConfig.chatPrompt,
        initialChatMessages: projectConfig.initialChatMessages,
        llmType: projectConfig.llmType,
        llmTemperature: projectConfig.llmTemperature,
        timezone: projectConfig.timezone || DateTime.local().zoneName,
      },
    });
  const initialChatMessages = useFieldArray({
    control,
    name: "initialChatMessages",
  });

  const profilePictureFilePath = watch("profilePictureFilePath");

  const [uploadProfilePic, , isUploadingFiles] = useUploadFiles({
    projectId: project.id,
    ownerId: project.id,
    ownerType: "project",
    onFileUploaded: (uploadedFile) => {
      setValue("profilePictureFilePath", uploadedFile.filePath);
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await adminUpdateProjectCallable({
          projectId: project.id,
          name: formValues.name,
          profilePictureFilePath: formValues.profilePictureFilePath,
          chatPrompt: formValues.chatPrompt,
          initialChatMessages: formValues.initialChatMessages,
          llmType: formValues.llmType,
          timezone: formValues.timezone,
          llmTemperature: formValues.llmTemperature,
        });
        toast.success(t("saved"));
      } catch (error) {
        handleError(error);
      }
    },
    [project.id]
  );

  const isLoading = formState.isSubmitting;

  return (
    <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">{t("settings")}</Typography>
        <Stack direction="row" spacing={1.5} alignItems="center">
          <ControlledTextField
            control={control}
            label={t("name")}
            name={"name"}
            autoComplete="off"
            disabled={isLoading}
            fullWidth
          />
          <ControlledSelect
            control={control}
            label="Timezone"
            name="timezone"
            items={TIMEZONES.map((tz: string) => ({
              label: tz,
              value: tz,
            }))}
            sx={{
              width: "200px",
            }}
            renderItem={(item) => item.value}
            disabled={isLoading}
          />
        </Stack>
        <Stack direction="row" spacing={1.5} alignItems="center">
          <FilePreview
            width={100}
            height={100}
            filePath={profilePictureFilePath}
            type={"IMAGE"}
          />
          <ControlledTextField
            control={control}
            label={t("profilePictureFilePath")}
            name="profilePictureFilePath"
            disabled={true}
            fullWidth
          />
          <FileInput
            disabled={isUploadingFiles}
            onSelect={uploadProfilePic}
            accept="image/jpg,image/jpeg,image/png,image/webp,audio/mp3"
            multiple
          >
            <Button variant="text" disabled={isUploadingFiles}>
              {isUploadingFiles && `${t("uploading")}...`}
              {!isUploadingFiles && t("changeProfilePicture")}
            </Button>
          </FileInput>
        </Stack>
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">{t("prompts")}</Typography>
        <Stack direction="row" spacing={3}>
          <ControlledSelect
            control={control}
            label={t("model")}
            name="llmType"
            items={LLM_TYPES.map((model) => ({
              label: model,
              value: model,
            }))}
            renderItem={(item) => item.value}
            disabled={isLoading}
            fullWidth
          />
          <ControlledTextField
            control={control}
            label={t("temperature")}
            name="llmTemperature"
            autoComplete="off"
            inputProps={{ step: 0.01 }}
            type="number"
            disabled={isLoading}
            fullWidth
          />
        </Stack>
        {/* {llmType === "gpt-3.5-fine-tuned" && (
          <Stack direction="row" spacing={3}>
            <Stack flex={1}>
              <ControlledSelect
                control={control}
                label="Custom Model"
                name="llmModelId"
                items={models
                  .filter((m) => !!m.defaultFineTunedModelId)
                  .map((model) => ({
                    label: model.name,
                    value: model.id,
                  }))}
                renderItem={(item) => item.label}
                onChange={() => setValue("llmFineTunedModelId", "")}
                disabled={isLoading}
                fullWidth
              />
            </Stack>
            <Stack flex={1}>
              {isLoadingVersions && <CircularProgress />}
              {!isLoadingVersions && !!versions.length && (
                <ControlledSelect
                  control={control}
                  label="Version"
                  name="llmFineTunedModelId"
                  emptyLabel="Default Version"
                  items={versions
                    .filter((v) => !!v.fineTunedModelId)
                    .map((version) => ({
                      label: toLocaleDateTimeString(version.createdAt),
                      value: version.fineTunedModelId || "",
                    }))}
                  renderItem={(item) => item.label}
                  disabled={isLoading || isLoadingVersions || !llmModelId}
                  fullWidth
                />
              )}
            </Stack>
          </Stack>
        )} */}
        <ControlledTextField
          control={control}
          label={t("chatPrompt")}
          name="chatPrompt"
          autoComplete="off"
          disabled={isLoading}
          multiline
        />
        <Stack direction="row" spacing={1}>
          {PROMPT_CONTEXT_KEYS.map((placeholder) => (
            <Chip key={placeholder} label={`{{${placeholder}}}`} />
          ))}
        </Stack>
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">{t("welcomeMessages")}</Typography>
        <Stack spacing={1.5}>
          {initialChatMessages.fields.map((field, index) => (
            <Fragment key={field.id}>
              <Stack spacing={1.5} direction="row">
                <ControlledTextField
                  control={control}
                  label={t("message")}
                  name={`initialChatMessages.${index}.text`}
                  autoComplete="off"
                  disabled={isLoading}
                  fullWidth
                  multiline
                />
                <IconButton
                  size="large"
                  onClick={() => initialChatMessages.remove(index)}
                  disabled={isLoading}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Stack>
              <AttachmentFormFields
                control={control}
                index={index}
                isLoading={isLoading}
                projectId={project.id}
              />
            </Fragment>
          ))}
          <Button
            onClick={() =>
              initialChatMessages.append({
                text: "",
                senderType: "AI",
                attachments: [],
              })
            }
            sx={{ alignSelf: "flex-start" }}
            disabled={isLoading}
          >
            {t("addInitialChatMessage")}
          </Button>
        </Stack>
      </Stack>
      <Button
        size="large"
        type="submit"
        variant="contained"
        sx={{ alignSelf: "flex-start" }}
        disabled={isLoading}
      >
        {t("save")}
      </Button>
    </Stack>
  );
};
