import { Stack, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { FC } from "react";

export const FirebaseAuthCaptionView: FC = () => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" spacing={0.5}>
      <Typography variant="ah5" color="primary.main">
        Stai.Chat
      </Typography>
      <Typography variant="ah5" color="common.black">
        {t("console")}
      </Typography>
    </Stack>
  );
};
