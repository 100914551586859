import Telegram from "@mui/icons-material/Telegram";
import WhatsApp from "@mui/icons-material/WhatsApp";
import Web from "@mui/icons-material/Web";
import { Box, ListItemButton, Stack, Typography } from "@mui/material";
import { Chat } from "@stai/types";
import { NavLink } from "react-router-dom";
import { toLocaleDateTimeString } from "../../Common/helpers/toLocaleDateTimeString";
import { t } from "i18next";

interface ChatListItemProps {
  to: string;
  chat: Chat;
}

function ChatListItem({ chat, to }: ChatListItemProps) {
  return (
    <ListItemButton
      key={chat.id}
      to={to}
      component={NavLink}
      className="chatsList"
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box width="20%">
          {chat.chatProvider === "Whatsapp" && <WhatsApp />}
          {chat.chatProvider === "Telegram" && <Telegram />}
          {chat.chatProvider === "Web" && <Web />}
        </Box>
        <Stack direction="column" width="80%">
          
        {chat.guestName ?
          <Typography>
            {chat.guestName}
          </Typography>
          : <Typography variant="subtitle2" color="grey">
          {t("anonymous") }
        </Typography>
}      
          <Typography variant="subtitle2" color="grey">
            {toLocaleDateTimeString(chat.updatedAt || chat.createdAt)}
          </Typography>
        </Stack>
      </Stack>
    </ListItemButton>
  );
}

export default ChatListItem;
