import { Pause, PictureAsPdf, PlayArrow, VideoFile } from "@mui/icons-material";
import { Stack, styled } from "@mui/material";
import { AttachmentConfig } from "@stai/types";
import { FC, useEffect, useRef, useState } from "react";
import { LoadingView } from "../../../Common/views/LoadingView";
import { useStorageFile } from "@stai/common";
interface Props {
  type: AttachmentConfig["type"];
  filePath: string;
  height?: number;
  width?: number;
}

export const FilePreview: FC<Props> = (props) => {
  const { filePath, width, height, type } = props;
  const { url, isLoading } = useStorageFile(filePath);

  return (
    <Stack sx={{ aspectRatio: 1, width, height }}>
      {isLoading && <LoadingView />}
      {url && type == "IMAGE" && <ImagePreview src={url} />}
      {url && type == "AUDIO" && <AudioPreview src={url} />}
      {url && type == "DOCUMENT" && <DocumentPreview src={url} />}
      {url && type == "VIDEO" && <VideoPreview src={url} />}
    </Stack>
  );
};

const ImagePreview = styled("div")<{ src?: string }>(({ src }) => ({
  position: "relative",
  objectFit: "contain", // or "cover"
  height: "100px",
  width: "100px",
  borderRadius: 4,
  background: src ? `url("${src}")` : undefined,
  backgroundPosition: "left",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
}));

const Wrapper = styled("div")({
  position: "relative",
  width: "100%",
  height: "100%",
  borderRadius: "4px",
  border: "1px solid black",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const AudioPreview = ({ src }: { src?: string }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const handlePlay = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (isPlaying && audioRef.current) {
      audioRef.current.play();
    } else if (!isPlaying && audioRef.current) {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  return (
    <Wrapper onClick={handlePlay}>
      {isPlaying ? <Pause /> : <PlayArrow />}
      <audio ref={audioRef} src={src} />
    </Wrapper>
  );
};

const DocumentPreview = ({ src }: { src?: string }) => {
  return (
    <Wrapper>
      <PictureAsPdf />
    </Wrapper>
  );
};

const VideoPreview = ({ src }: { src?: string }) => {
  return (
    <Wrapper>
      <VideoFile />
    </Wrapper>
  );
};
