import { Timestamp } from "@stai/types";
import { DateTime } from "luxon";

export const toLocaleDateTimeString = (timestamp?: Timestamp) => {
  if (!timestamp) return "";
  const date = DateTime.fromJSDate(timestamp.toDate());
  if (DateTime.now().hasSame(date, "day")) return date.toFormat("HH:mm");

  return date.toFormat("dd/M/yyyy HH:mm");
};
