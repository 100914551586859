import { getCollectionRef, useValidCollectionData } from "@stai/common";
import {
  AttachmentConfig,
  AttachmentConfigsCollection,
  FileType,
} from "@stai/types";
import { query, where } from "firebase/firestore";

export function useAttachmentConfigs(projectId?: string, fileType?: FileType) {
  if (!projectId) return [];
  return useValidCollectionData(
    query(
      getCollectionRef(AttachmentConfigsCollection(projectId)),
      ...(fileType ? [where("type", "==", fileType)] : [])
      // orderBy("createdAt", "asc")
    ),
    AttachmentConfig
  );
}
