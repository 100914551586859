import { Button, Stack } from "@mui/material";
import { useTranslation } from "next-i18next";
import { FC, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePrevious } from "../../Common/hooks/usePrevious";
import { MainContainer } from "../../Main/views/MainContainer";
import { useProject } from "../../Project/hooks/useProject";
import { useStay } from "../../Project/hooks/useStay";
import { useChat } from "../hooks/useChat";
import { useChatMessagesByChatId } from "../hooks/useChatMessagesByChatId";
import { ChatInputView } from "./ChatInputView";
import { ChatMessageView } from "./ChatMessageView";
import { ChatPromptDialog } from "./ChatPromptDialog";
import { ChatHeader } from "./ChatHeader"; 

function important<T>(value: T): T {
  return (String(value) + " !important") as T;
}

interface ChatPageRouteParams {
  chatId: string;
  projectId: string;
}

export const ChatPage: FC = () => {
  const { t } = useTranslation();
  const { chatId, projectId } = useParams<
    keyof ChatPageRouteParams
  >() as ChatPageRouteParams;
  const scrollRef = useRef<HTMLDivElement>(null);
  const [chat] = useChat(projectId, chatId);
  const [project] = useProject(chat?.projectId);
  const [stay] = useStay(projectId, chat?.stayId);
  const navigate = useNavigate();
  const [chatMessages, , , controller] = useChatMessagesByChatId(
    projectId,
    chatId
  );
  const currentLength = chatMessages.length;
  const previousLength = usePrevious(currentLength);

  useEffect(() => {
    if (!scrollRef.current) return;
    if (currentLength === previousLength) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    scrollRef.current.scrollIntoView({
      behavior: previousLength ? "smooth" : "instant",
    });
  }, [currentLength, previousLength]);

  if (!project) return null;
  if (!chat) return null;

  return (
    <Stack height={"100%"}>
      <ChatHeader chat={chat} projectId={projectId} stay={stay} />
      <MainContainer
        maxWidth={false}
        sx={{ height: "100%", padding: important(0) }}
      >
        <Stack p={3} spacing={3} flex={1}>
          {controller.hasMore && (
            <Stack mx={2} my={1}>
              <Button onClick={controller.loadMore}>{t("loadMore")}</Button>
            </Stack>
          )}
          {chatMessages
            .sort((a, b) => a.createdAt.toMillis() - b.createdAt.toMillis())
            .map((chatMessage) => (
              <ChatMessageView
                key={chatMessage.id}
                chatId={chat?.id}
                projectId={projectId}
                chatMessage={chatMessage}
              />
            ))}
          <Stack ref={scrollRef} />
        </Stack>
        <ChatInputView projectId={projectId} chatId={chatId} />
      </MainContainer>
      <ChatPromptDialog />
    </Stack>
  );
};
