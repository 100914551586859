import CloseIcon from '@mui/icons-material/Close';
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, Paper, Stack, Typography } from "@mui/material";
import { generateStartMessage, generateTelegramUrl, generateWhatsappUrl, ProjectConfig } from '@stai/types';
import { useTranslation } from "next-i18next";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SecondarySidebar } from "../../../Common/components/sidebar/SecondarySidebar";
import { toLocaleDayMonthString } from '../../../Common/helpers/toLocaleDayMonthString';
import { COLORS } from '../../../Main/consts/MAIN_PALETTE';
import { useAppConfig } from '../../../Superadmin/hooks/useAppConfig';
import { useProject } from '../../hooks/useProject';
import { useStay } from '../../hooks/useStay';
import { QRCodeDialog } from "./QRDialog";
import { StayForm } from "./StayForm";
import { create } from 'domain';
import { app } from '@stai/common';
import { useProjectConfig } from '../../hooks/useProjectConfig';

type PathParams = {
    projectId: string;
};


export const TryPage = () => {
    const params = useParams<PathParams>();
    const { projectId } = params;

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [isOpenForm, setIsOpenForm] = useState(false);
    const [appConfig, loadingAppConfig] = useAppConfig("default");
    const [projectConfig, loading] = useProjectConfig(projectId);

    const [isQRCodeDialogOpen, setIsQRCodeDialogOpen] = useState(false);
    const [selectedQRCodeUrl, setSelectedQRCodeUrl] = useState("");

    const handleQRCodeDialogClose = useCallback(() => {
        setIsQRCodeDialogOpen(false);
    }, []);

    const handleQRCodeDialogOpen = useCallback((url: string) => {
        setSelectedQRCodeUrl(url);
        setIsQRCodeDialogOpen(true);
    }, []);

    const handleCloseForm = useCallback(() => {
        setIsOpenForm(false);
    }, []);

    const handleOpenForm = useCallback(() => {
        setIsOpenForm(true);
    }, []);

    const createAnonymousChat = useCallback(() => {
        setStayId(undefined);
        if (projectId) {
            navigate(`/projects/${projectId}/try/anonymous`);
        }
    }, [projectId]);

    const createPersonalizedChat = useCallback((stayId: string) => {
        setStayId(stayId);
        if (projectId) {
            handleCloseForm();
            navigate(`/projects/${projectId}/try/stays/${stayId}`);
        }
    }, [projectId]);

    const [stayId, setStayId] = useState<string>();
    const [stay] = useStay(projectId, stayId);

    const browserLanguage = navigator.language ?? "en-GB";
    const startMessage = generateStartMessage({
        stayId,
        projectId: projectId!,
        language: browserLanguage,
    });
    const startMessageAnonymous = generateStartMessage({
        projectId: projectId!,
        language: browserLanguage,
    });

    const whatsappIntegration = projectConfig?.whatsappIntegration || appConfig?.whatsappSharedIntegration;

    const telegramUrl = generateTelegramUrl(projectConfig?.telegramBotName, startMessage);
    const whatsappUrl = generateWhatsappUrl(whatsappIntegration?.phoneNumber, startMessage);
    const whatsappUrlAnonymus = generateWhatsappUrl(whatsappIntegration?.phoneNumber, startMessageAnonymous);
    const telegramUrlAnonymus = generateTelegramUrl(projectConfig?.telegramBotName, startMessageAnonymous);

    return (
        <SecondarySidebar width={500}>
            <Stack padding={3} spacing={3}>
                <Stack component={Paper} p={2} spacing={3}>
                    <Typography variant="ah3">{t("try.page.header")}</Typography>
                    <Typography variant="body1">{t("try.page.description")}</Typography>
                </Stack>
                <Button
                    variant="contained"
                    color="success"
                    size="large"
                    sx={{
                        marginTop: "10px",
                    }}
                    onClick={createAnonymousChat}
                >
                    {t("try.page.create_anonymous")}
                </Button>
                <Stack component={Paper} p={2} spacing={3}>
                    <Typography variant="body2">{t("try.page.anonymous.description")}</Typography>
                </Stack>

                <Stack flex={1}>
                    {whatsappUrlAnonymus && <Stack flex={1} p={2} spacing={3} alignItems="center" justifyContent="center">
                        <WhatsAppIcon sx={{ fontSize: { xs: 30, sm: 40, md: 50 }, color: COLORS.whatsapp, cursor: 'pointer' }} onClick={() => handleQRCodeDialogOpen(whatsappUrlAnonymus)} />
                        Whatsapp
                    </Stack>
                    }

                    {telegramUrlAnonymus && <Stack p={2} flex={1} spacing={3} alignItems="center" justifyContent="center">
                        <TelegramIcon sx={{ fontSize: { xs: 30, sm: 40, md: 50, color: COLORS.telegram, cursor: 'pointer' } }} onClick={() => handleQRCodeDialogOpen(telegramUrlAnonymus)} />
                        Telegram
                    </Stack>
                    }
                </Stack>

                <Divider>
                    {t("try.page.or")}
                </Divider>
                <Button
                    variant="contained"
                    color="success"
                    size="large"
                    sx={{
                        marginTop: "10px",
                    }}
                    onClick={handleOpenForm}
                >
                    {t("try.page.create_personalised")}
                </Button>
                <Stack component={Paper} p={2} spacing={3}>
                    <Typography variant="body2">{t("try.page.personalised.description")}</Typography>
                </Stack>


                {stay &&
                <>
                        <Stack flex={1}>
                            <Typography
                                sx={{ cursor: "pointer" }}
                                align={"center"}
                                variant={"body1"}
                            >
                                {t("name")} <strong>{stay.name}</strong>. {t("room_number")} <strong>{stay.roomNumber}</strong>
                                <br />
                                {t("check_in_date")} <strong>{toLocaleDayMonthString(stay.checkinDate)}</strong>, {t("check_out_date")}:{" "}
                                <strong>{toLocaleDayMonthString(stay.checkoutDate)}</strong>
                            </Typography>
                        </Stack>
                    <Stack flex={1}>
                        {whatsappUrl && stayId &&<Stack flex={1} p={2} spacing={3} alignItems="center" justifyContent="center">
                            <WhatsAppIcon sx={{ fontSize: { xs: 30, sm: 40, md: 50 }, color: COLORS.whatsapp, cursor: 'pointer' }} onClick={() => handleQRCodeDialogOpen(whatsappUrl)} />
                            Whatsapp
                        </Stack>
                        }

                        {telegramUrl && stayId && <Stack p={2} flex={1} spacing={3} alignItems="center" justifyContent="center">
                            <TelegramIcon sx={{ fontSize: { xs: 30, sm: 40, md: 50, color: COLORS.telegram, cursor: 'pointer' } }} onClick={() => handleQRCodeDialogOpen(telegramUrl)} />
                            Telegram
                        </Stack>
                        }
                    </Stack>
                    </>}
                <Dialog open={isOpenForm} onClose={handleCloseForm}
                    maxWidth="md"
                    fullWidth>
                    <DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseForm}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <StayForm projectId={projectId!} onCompleted={(stayId) => createPersonalizedChat(stayId)} /> 
                    </DialogContent>
                </Dialog>
                <QRCodeDialog
                    open={isQRCodeDialogOpen}
                    onClose={handleQRCodeDialogClose}
                    url={selectedQRCodeUrl}
                />
            </Stack>

        </SecondarySidebar>
    );
};
