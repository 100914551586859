import { getCollectionRef, useValidCollectionData } from "@stai/common";
import { KnowledgeConfig, KnowledgeConfigsCollection } from "@stai/types";
import { orderBy, query } from "firebase/firestore";

export function useKnowledgeConfigs(projectId?: string) {
  return useValidCollectionData(
    projectId
      ? query(
          getCollectionRef(KnowledgeConfigsCollection(projectId)),
          orderBy("createdAt", "asc")
        )
      : null,
    KnowledgeConfig
  );
}
