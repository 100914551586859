import { Paper, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { ErrorPage } from "../../../Common/views/ErrorPage";
import { LoadingPage } from "../../../Common/views/LoadingPage";
import { useAppConfig } from "../../../Superadmin/hooks/useAppConfig";
import { WhatsappSharedForm } from "./WhatsappSharedForm";
import { t } from "i18next";

export const WhatsappSharedPage: FC = () => {

  const [appConfig, loading, error] = useAppConfig("default");

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (loading || !appConfig) {
    return <LoadingPage />;
  }

  return (
    <Stack padding={3}>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">{t("whatsappshared.page.title")}</Typography>
        <Typography variant="body1">
          {t("whatsappshared.page.description")}
        </Typography>
      </Stack>
      <WhatsappSharedForm whatappSharedIntegration={appConfig.whatsappSharedIntegration} />
    </Stack>
  );
};
