import { getDocRef, useValidDocumentData } from "@stai/common";
import { ChatPrompt, ChatPromptsCollection } from "@stai/types";

export function useChatPrompt(
  projectId?: string,
  chatId?: string,
  chatMessageId?: string
) {
  return useValidDocumentData(
    projectId && chatId && chatMessageId
      ? getDocRef(ChatPromptsCollection(projectId, chatId), chatMessageId)
      : null,
    ChatPrompt
  );
}
