import { getDocRef, useValidDocumentData } from "@stai/common";
import { ApaleoIntegration, ApaleoIntegrationCollection } from "@stai/types";

export function useApaleoIntegration(projectId?: string) {
  return useValidDocumentData(
    projectId
      ? getDocRef(ApaleoIntegrationCollection(projectId), "apaleo")
      : null,
    ApaleoIntegration
  );
}
