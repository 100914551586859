import { getCollectionRef, usePaginatedCollection } from "@stai/common";
import { FirebaseFilter, Project, ProjectsCollection } from "@stai/types";
import { query, where } from "firebase/firestore";
export function useProjects(filters?: FirebaseFilter<Project>[]) {
  return usePaginatedCollection(
    query(
      getCollectionRef(ProjectsCollection),
      ...(filters ? filters.map((filter) => where(...filter)) : [])
    ),
    Project
  );
}
