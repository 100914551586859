import Add from "@mui/icons-material/Add";
import { Button, ListItemButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ErrorView } from "../../../Common/views/ErrorView";
import { SecondarySidebarList } from "../../../Common/components/sidebar/SecondarySidebarList";
import { useKnowledgeConfigs } from "./hooks/useKnowledgeConfigs";
import { SecondarySidebar } from "../../../Common/components/sidebar/SecondarySidebar";

export const KnowledgeList = () => {
  const { t } = useTranslation();
  const { projectId } = useParams<"projectId">();
  const [knowledges, , error] = useKnowledgeConfigs(projectId);
  const navigate = useNavigate();

  return (
    <SecondarySidebar>
      <Stack px={3} pt={3} pb={1} spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="ah2">{t("knowledges")}</Typography>
        </Stack>
      </Stack>
      {error && <ErrorView error={error} />}
      <SecondarySidebarList>
        {knowledges
          .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())
          .map((knowledge) => (
            <ListItemButton
              key={knowledge.id}
              to={knowledge.id}
              component={NavLink}
            >
              {knowledge.title}
            </ListItemButton>
          ))}
        <Stack mx={2} my={5}>
          <Button
            size="large"
            variant="outlined"
            sx={{ alignSelf: "flex-start" }}
            onClick={() => navigate("new")}
            fullWidth
          >
            <Add />
            {t("addKnowledge")}
          </Button>
        </Stack>
      </SecondarySidebarList>
    </SecondarySidebar>
  );
};
