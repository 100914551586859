import { Button, Icon, Stack } from "@mui/material";
import { ApaleoIntegration, DeleteApaleoIntegrationDefinition } from "@stai/types";
import { useTranslation } from "next-i18next";
import { FC, useCallback } from "react";
import { APALEO_SVG_LOGO_PATH } from "../../../Common/assets";
import { createCallable } from "@stai/common";
import toast from "react-hot-toast";
import { handleError } from "../../../Common/helpers/handleError";
import DeleteIcon from "@mui/icons-material/Delete";


interface Props {
  projectId: string;
  apaleoIntegration?: ApaleoIntegration;
}

const deleteApaleoIntegrationCallable = createCallable(
  DeleteApaleoIntegrationDefinition
);


export const ApaleoConnectForm: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { projectId, apaleoIntegration } = props;

  const onDelete = useCallback(async () => {
    try {
      await deleteApaleoIntegrationCallable({
        projectId: projectId,
      });

      toast.success(t("ok"));
    } catch (error) {
      handleError(error);
    }
  }, [projectId]);

  const toConsentScreen = useCallback(() => {
    const code = projectId;
    const apaleoConsentUrl = `https://identity.apaleo.com/connect/authorize?response_type=code&scope=${process.env.NEXT_PUBLIC_APALEO_REQUIRED_SCOPES}&client_id=${process.env.NEXT_PUBLIC_APALEO_APP_ID}&redirect_uri=${process.env.NEXT_PUBLIC_APALEO_REDIRECT_URL}&state=${code}`;
    window.open(apaleoConsentUrl, "_blank");
  }, [projectId]);

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between">
      <Button
        size="large"
        variant="contained"
        onClick={toConsentScreen}
      >
        {t("apaleo.connect.button")}
        <img src={APALEO_SVG_LOGO_PATH} alt="Apaleo" height={20} style={{ marginLeft: "10px" }} />
      </Button>

      {apaleoIntegration?.accessToken && (
        <Button
            size="large"
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={onDelete}
          >
            {t("apaleo.connect.disconnect")}
        </Button>
      )}
    </Stack>
  );
};
