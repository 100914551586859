import { List, styled } from "@mui/material";

export const SideBarList = styled(List)(({ theme }) => ({
    "& .MuiListItemButton-root": {
      "&.active": {
        color: theme.palette.primary.main,
        ".MuiListItemIcon-root": {
          color: theme.palette.primary.main,
        },
      },
    },
    "& .MuiListItemIcon-root": {
      minWidth: 38,
    },
  }));
  