import { zodResolver } from "@hookform/resolvers/zod";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, Typography } from "@mui/material";
import { createCallable } from "@stai/common";
import {
  StaffNotificationConfig,
  UpdateStaffNotificationsDefinition
} from "@stai/types";
import { useTranslation } from "next-i18next";
import { FC, useCallback, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import { z } from "zod";
import { ControlledTextField } from "../../../Common/components/forms/ControlledTextField";
import { handleError } from "../../../Common/helpers/handleError";
import { useAppConfig } from "../../../Superadmin/hooks/useAppConfig";
import { Add } from "iconsax-react";
import { Delete } from "@mui/icons-material";

const updateStuffNotificationConfigCallable = createCallable(
  UpdateStaffNotificationsDefinition
);

const FormValues = z.object({
  telegramHandles: z.array(z.object({id:z.string(), name:z.string()})).default([]),
  botGuidance: z.string().min(1),
  enabled: z.boolean(),
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  projectId: string;
  staffNotificationConfig?: StaffNotificationConfig;
}

export const NotifyStaffForm: FC<Props> = (props) => {
  const { t } = useTranslation();
  const [appConfig, loadingAppConfig] = useAppConfig("default");
  const { staffNotificationConfig, projectId } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const defaultValues = {
    telegramHandles: staffNotificationConfig?.telegramHandles.map((handle) => ({id:handle, name:handle})) || [{id:"", name:""}],
    botGuidance: staffNotificationConfig?.botGuidance || t("staff.form.botGuidance.default"),
    enabled: staffNotificationConfig?.enabled || true,
  };

  const { control, handleSubmit, formState, reset } = useForm<FormValues>({
    defaultValues: defaultValues,
    resolver: zodResolver(FormValues),
  });

  const { fields: telegramHandles, append, remove } = useFieldArray({
    control,
    name: "telegramHandles",
  });

  useEffect(() => {
    reset(defaultValues);
  }, []);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await updateStuffNotificationConfigCallable({
          projectId: projectId,
          telegramHandles:  formValues.telegramHandles.map((handle) => handle.name),
          botGuidance: formValues.botGuidance,
          enabled: formValues.enabled,
        });

        toast.success(t("saved"));
        setIsDialogOpen(true);
      } catch (error) {
        handleError(error);
      }
    },
    [projectId]
  );

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const isLoading = formState.isSubmitting;
  const isValid = formState.isValid;
  const botUrl = `https://t.me/${appConfig?.staffBotName}?start=start`;

  return (
    <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack component={Paper} p={2} spacing={2}>
        <Typography>{t("staff.form.step1", { handle: appConfig?.staffBotName })}</Typography>

        <Link target="_blank" to={botUrl} >{botUrl}</Link>
        <QRCode value={botUrl} size={100} />
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography>{t("staff.form.step2", { handle: appConfig?.staffBotName })}</Typography>
        {telegramHandles.map((field, index) => (
          <Stack key={field.id} direction="row" spacing={2} alignItems="center" maxWidth="400px">
            <ControlledTextField
              control={control}
              label={t("staff.form.userHandle", { handle: appConfig?.staffBotName })}
              name={`telegramHandles.${index}.name`}
              defaultValue={field.name}
              autoComplete="off"
              fullWidth
            />
              <IconButton onClick={() => remove(index)}>
                <Delete />
              </IconButton>
          </Stack>
        ))}
        <Stack direction="row" spacing={2} alignItems="center" maxWidth="400px">
        <Button
          startIcon={<Add />}
          onClick={() => append({id: "", name: ""})}
          variant="contained"
          fullWidth
        >
        </Button>
        </Stack>
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography>{t("staff.form.step3")}</Typography>
        <ControlledTextField
          control={control}
          label={t("staff.form.botGuidance")}
          name="botGuidance"
          autoComplete="off"
          multiline
        />
      </Stack>
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{t("staff.form.dialog.title")}</DialogTitle>
        <DialogContent>
          <Typography>{t("staff.form.dialog.description")}</Typography>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Button
          size="large"
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          disabled={isLoading || !isValid}
        >
          {t("save")}
        </Button>
      </Stack>
    </Stack>
  );
};