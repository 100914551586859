import { FirebaseFilter, Stay, StayCollection } from "@stai/types";
import { orderBy, query, where } from "firebase/firestore";
import { getCollectionRef, usePaginatedCollection } from "@stai/common";

export function useStays(
  projectId?: string,
  filters: FirebaseFilter<Stay>[] = []
) {
  if (!projectId) return [];
  return usePaginatedCollection(
    query(
      getCollectionRef(StayCollection(projectId)),
      ...filters.map((filter) => where(...filter)),
      orderBy("createdAt", "desc")
    ),
    Stay
  );
}
