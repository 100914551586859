import { Divider, Stack, TextField } from "@mui/material";
import { ChatPrompt } from "@stai/types";
import { useTranslation } from "next-i18next";
import { FC } from "react";

interface Props {
  chatPrompt: ChatPrompt;
}

export const ChatPromptDialogTabTools: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { chatPrompt } = props;

  const executedTools = chatPrompt.toolCalls?.filter(
    (toolCall) => toolCall.parameters
  );
  const otherTools = chatPrompt.toolCalls?.filter(
    (toolCall) => !toolCall.parameters
  );

  return (
    <Stack spacing={3}>
      <TextField value={chatPrompt.phase} label={t("phases")} fullWidth />
      <Divider>{t("toolCalls")}</Divider>
      {executedTools &&
        executedTools.map((toolCall, index, array) => (
          <Stack key={toolCall.toolName} spacing={2}>
            <TextField
              value={toolCall.toolType}
              label={t("toolType")}
              sx={{ textarea: { fontSize: 14 } }}
              fullWidth
            />
            <TextField
              value={toolCall.toolDescription}
              label={t("toolDescription")}
              sx={{ textarea: { fontSize: 14 } }}
              fullWidth
              rows={4}
              multiline
            />
            <TextField
              value={JSON.stringify(toolCall.parameters)}
              label={t("callParams")}
              sx={{ textarea: { fontSize: 14 } }}
              fullWidth
              multiline
            />
            {toolCall.toolOutput && (
              <TextField
                value={toolCall.toolOutput}
                label={t("output")}
                sx={{ textarea: { fontSize: 14 } }}
                fullWidth
                multiline
              />
            )}
            {toolCall.error && (
              <TextField
                value={toolCall.error}
                label={t("error")}
                sx={{ textarea: { fontSize: 14 } }}
                fullWidth
                multiline
              />
            )}
            {index < array.length - 1 && <Divider></Divider>}
          </Stack>
        ))}
      <Divider>{t("otherAvailableTools")}</Divider>
      {otherTools &&
        otherTools.map((toolCall, index, array) => (
          <Stack key={toolCall.toolName} spacing={2}>
            <TextField
              value={toolCall.toolType}
              label={t("toolType")}
              sx={{ textarea: { fontSize: 14 } }}
              fullWidth
            />
            <TextField
              value={toolCall.toolName}
              label={t("toolName")}
              sx={{ textarea: { fontSize: 14 } }}
              fullWidth
            />
            <TextField
              value={toolCall.toolDescription}
              label={t("toolDescription")}
              sx={{ textarea: { fontSize: 14 } }}
              fullWidth
              rows={4}
              multiline
            />
            {index < array.length - 1 && <Divider></Divider>}
          </Stack>
        ))}
    </Stack>
  );
};
