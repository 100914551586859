import { auth, useMyUserRecord } from "@stai/common";
import { FC, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { ErrorPage } from "../../Common/views/ErrorPage";
import { LoadingPage } from "../../Common/views/LoadingPage";
import { setupSentryUser } from "../../Setup/setupSentry";
import { MainLoggedOutRoutes } from "./MainLoggedOutRoutes";
import { MainRoutes } from "./MainRoutes";

export const MainInitView: FC = () => {
  const [authState, isInitializingAuthState, authUserError] = useAuthState(auth);
  const { user: userRecord, isInitializing: userRecordInitializing, error: userRecordError } = useMyUserRecord();

  const userId = authState?.uid;

  useEffect(() => {
    if (userId) {
      useMyUserRecord.getState().subscribe(userId);
    }

    return () => {
      useMyUserRecord.getState().unsubscribe?.();
    };
  }, [userId]);

  useEffect(() => {
    if (authState) {
      setupSentryUser(authState);
    }
  }, [userId]);

  const isInitializing = isInitializingAuthState || Boolean(authState && userRecordInitializing)

  const user = useMyUserRecord((state) => state.user);
  const isLoggedIn = !!user;
  const isAdmin = userRecord?.role === "ADMIN"
  const isSuperAdmin = userRecord?.role === "SUPERADMIN"

  if (isInitializing) return <LoadingPage />;

  if (userRecordError) return <ErrorPage error={userRecordError} />;
  if (authUserError) return <ErrorPage error={authUserError} />;

  if (!isLoggedIn) {
    return <MainLoggedOutRoutes />;
  }

  if (!isAdmin && !isSuperAdmin) {
    // TODO propper error message
    return <ErrorPage error={new Error("Admin Access only")} />;
  }

  return <MainRoutes />;
};
