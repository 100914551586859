import { Stack, Typography } from "@mui/material";
import { ChatMessage } from "@stai/types";
import { FC, Fragment, useCallback } from "react";
import { StorageFile } from "../../Common/types/StorageFile";
import { FilePreview } from "../../Project/views/ProjectPromtPage/FilePreview";
import { useChatPromptDialog } from "../hooks/useChatPromptDialog";

interface Props {
  chatId?: string;
  projectId?: string;
  chatMessage: ChatMessage;
  projectProfilePicture?: StorageFile;
}

export const ChatMessageView: FC<Props> = (props) => {
  const { chatId, chatMessage, projectId } = props;

  const isChatMessageTextVisible = !chatMessage.attachments
    ?.map((a) => a.type)
    .includes("AUDIO");

  const onClick = useCallback(() => {
    if (!chatId || !projectId) return;

    useChatPromptDialog.getState().open(projectId, chatId, chatMessage.id);
  }, [chatId, chatMessage.id]);

  return (
    <Stack
      px={1}
      direction={chatMessage.senderType === "USER" ? "row-reverse" : "row"}
      justifyContent="flex-start"
      spacing={1}
    >
      <Stack
        maxWidth="80%"
        width={chatMessage.attachments?.length ? "100%" : undefined}
        onClick={onClick}
        sx={{ cursor: "pointer" }}
      >
        <Stack
          p={1.5}
          borderRadius="18px"
          border="1px solid"
          borderColor="grey.400"
          position="relative"
          spacing={1}
        >
          {chatMessage.attachments?.map((attachment, index) => (
            <Fragment key={index}>
              {attachment.type === "IMAGE" && (
                <FilePreview
                  type="IMAGE"
                  height={300}
                  filePath={attachment.filePath}
                />
              )}
              {attachment.type === "AUDIO" && <Stack>🔈 Audio</Stack>}
            </Fragment>
          ))}
          {isChatMessageTextVisible && (
            <Typography variant="body2">{chatMessage.text}</Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
