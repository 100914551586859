import { create } from "zustand";

interface State {
  isOpen: boolean;
  projectId?: string;
  chatId?: string;
  chatMessageId?: string;
  open: (projectId: string, chatId: string, chatMessageId: string) => void;
  close: () => void;
}

export const useChatPromptDialog = create<State>((set) => ({
  isOpen: false,
  open: (projectId, chatId, chatMessageId) => {
    set({ isOpen: true, projectId, chatId, chatMessageId });
  },
  close: () => {
    set({
      isOpen: false,
      projectId: undefined,
      chatId: undefined,
      chatMessageId: undefined,
    });
  },
}));
