import React from 'react';
import { useParams } from 'react-router-dom';

export const ChatIframe: React.FC = () => {

    const { projectId, chatId, stayId } = useParams<{ projectId: string; chatId: string, stayId?: string }>();


    let chatUrl = `${process.env.NEXT_PUBLIC_MESSENGER_DOMAIN}/projects/${projectId}`; 
    
    switch(true){
        case !!stayId: chatUrl = `${process.env.NEXT_PUBLIC_MESSENGER_DOMAIN}/projects/${projectId}/stays/${stayId}`; break;
        case !!chatId: chatUrl = `${process.env.NEXT_PUBLIC_MESSENGER_DOMAIN}/projects/${projectId}/chats/${chatId}`; break;
    }

    return (
        // <div
        //     style={{
        //         width: '100%',
        //         height: '100%',
        //         margin: 0,
        //         padding: 0,
        //         border: 'none',
        //     }}
        // >
            <iframe
                src={chatUrl}
                style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                }}
                title="Chat"
            />
        // </div>
    );
};