import { CircularProgress, Stack, Typography } from "@mui/material";
import { FC } from "react";

export const LoadingView: FC = () => {
  return (
    <Stack flex={1} spacing={4} alignItems="center" justifyContent="center">
      <CircularProgress color="primary" size={40} />
      <Typography>Loading...</Typography>
    </Stack>
  );
};
