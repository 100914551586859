import { zodResolver } from "@hookform/resolvers/zod";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton, Stack } from "@mui/material";
import { createCallable } from "@stai/common";
import {
  AddAttachmentConfigDefinition,
  DeleteAttachmentConfigDefinition,
  UpdateAttachmentConfigDefinition,
} from "@stai/types";
import { AttachmentConfig } from "@stai/types/types/AttachmentConfig";
import { useTranslation } from "next-i18next";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { handleError } from "../../../Common/helpers/handleError";
import { ControlledTextField } from "../../../Common/components/forms/ControlledTextField";
import { FilePreview } from "../ProjectPromtPage/FilePreview";
import { FormValues } from "./FormValues";

const addAttachmentConfigCallable = createCallable(
  AddAttachmentConfigDefinition
);
const deleteAttachmentConfigCallable = createCallable(
  DeleteAttachmentConfigDefinition
);
const updateAttachmentConfigCallable = createCallable(
  UpdateAttachmentConfigDefinition
);

interface Props {
  projectId: string;
  attachmentConfig: AttachmentConfig | Partial<AttachmentConfig>;
  cancelCreateNew?: () => void;
}

export const AttachmentConfigForm: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { projectId, attachmentConfig, cancelCreateNew } = props;

  const { control, handleSubmit, formState, watch } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: attachmentConfig,
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (!attachmentConfig.id) {
          await addAttachmentConfigCallable({
            projectId: projectId,
            ...formValues,
          });
          cancelCreateNew?.();
        } else {
          await updateAttachmentConfigCallable({
            projectId: projectId,
            attachmentConfigId: attachmentConfig.id,
            ...formValues,
          });
        }
        toast.success(t("saved"));
      } catch (error) {
        toast.success("Error!");
        handleError(error);
      }
    },
    [projectId]
  );

  const onDelete = useCallback(() => {
    if (attachmentConfig.id) {
      deleteAttachmentConfigCallable({
        attachmentConfigId: attachmentConfig.id,
        projectId: projectId,
      });
      toast.success("Deleted!");
    } else {
      cancelCreateNew?.();
    }
  }, [attachmentConfig, projectId]);

  const filePath = watch("filePath");
  const type = watch("type");

  const isLoading = formState.isSubmitting;

  return (
    <Stack
      direction="row"
      spacing={1.5}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      alignItems="center"
    >
      <FilePreview width={100} height={100} filePath={filePath} type={type} />

      <ControlledTextField
        control={control}
        name={"context"}
        label={t("context")}
        autoComplete="off"
        disabled={isLoading}
        fullWidth
      />

      <Stack justifyContent="center">
        <IconButton size="large" onClick={onDelete} disabled={isLoading}>
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </Stack>
      <Button type="submit" variant="text">
        {attachmentConfig.id ? t("update") : t("save")}
      </Button>
    </Stack>
  );
};
