import { List, styled } from "@mui/material";

export const SecondarySidebarList = styled(List)(({ theme }) => ({
  "& .MuiListItemButton-root": {
    padding: theme.spacing(1, 3),
    margin: "5px 20px",
    fontWeight: "700",
    fontSize: "16px",
    borderColor: theme.palette.primary.main,
    border: "1px solid",
    "&.active": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    "&.chatsList": {
      borderRadius: "10px !important",
      margin: "5px 10px",
      borderColor: theme.palette.divider,
      padding: "10px",
    },
  },
}));
