import { getDocRef, useValidDocumentData } from "@stai/common";
import { ApiToolConfig, ApiToolConfigsCollection } from "@stai/types";

export function useApiToolConfig(projectId?: string, apiToolId?: string) {
  return useValidDocumentData(
    projectId && apiToolId
      ? getDocRef(ApiToolConfigsCollection(projectId), apiToolId)
      : null,
    ApiToolConfig
  );
}
