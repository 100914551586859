import { AudioFile, Image, PictureAsPdf, VideoFile } from "@mui/icons-material";
import { ListItemButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { SecondarySidebarList } from "../../../Common/components/sidebar/SecondarySidebarList";
import { SecondarySidebar } from "../../../Common/components/sidebar/SecondarySidebar";

export const AttachmentsList: FC = () => {
  const { t } = useTranslation();
  return (
    <SecondarySidebar>
      <Stack px={3} pt={3} pb={1} spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="ah2">{t("attachments")}</Typography>
        </Stack>
      </Stack>
      <SecondarySidebarList>
        <ListItemButton to={"images"} component={NavLink}>
          <Image />
          <Typography paddingLeft={1}>{t("images")}</Typography>
        </ListItemButton>
        <ListItemButton to={"documents"} component={NavLink}>
          <PictureAsPdf />
          <Typography paddingLeft={1}>{t("pdf")}</Typography>
        </ListItemButton>
        <ListItemButton to={"audios"} component={NavLink}>
          <AudioFile />
          <Typography paddingLeft={1}>{t("audio")}</Typography>
        </ListItemButton>
        <ListItemButton to={"videos"} component={NavLink}>
          <VideoFile />
          <Typography paddingLeft={1}>{t("video")}</Typography>
        </ListItemButton>
      </SecondarySidebarList>
    </SecondarySidebar>
  );
};
