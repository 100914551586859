import { zodResolver } from "@hookform/resolvers/zod";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { createCallable } from "@stai/common";
import { AddStayDefinition, UpdateStayDefinition } from "@stai/types";
import { DateTime } from "luxon";
import { useTranslation } from "next-i18next";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { ControlledDatePicker } from "../../../Common/components/forms/ControlledDatePicker";
import { ControlledTextField } from "../../../Common/components/forms/ControlledTextField";
import { handleError } from "../../../Common/helpers/handleError";
import { ErrorPage } from "../../../Common/views/ErrorPage";
import { LoadingPage } from "../../../Common/views/LoadingPage";
import { LoadingView } from "../../../Common/views/LoadingView";
import { useProjectConfig } from "../../hooks/useProjectConfig";
import { useStay } from "../../hooks/useStay";

const addStayCallable = createCallable(AddStayDefinition);
const updateStayCallable = createCallable(UpdateStayDefinition);

const luxonDateTime = z.custom((val: any) => {
  return val instanceof DateTime;
});

const FormValues = z.object({
  name: z.string(),
  email: z.string().email(),
  checkinDate: luxonDateTime,
  checkoutDate: luxonDateTime.optional(),
  roomNumber: z.string().optional(),
});

type FormValues = z.infer<typeof FormValues>;

type Params = {
  projectId: string;
  stayId?: string;
  onCompleted?: (stayId: string) => void;
};

export const StayForm: FC<Params> = ({projectId, stayId, onCompleted}: Params) => {
  

  const [project, isLoadingProject] = useProjectConfig(projectId);
  const [stay, isLoadingStay] = useStay(projectId, stayId);
  const [isCreating, setIsCreating] = useState(false);
  const { control, handleSubmit, reset, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: { name: "", checkinDate: DateTime.now(), email: "", roomNumber: "", checkoutDate: undefined },
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (stayId && stay) {
      reset({
        name: stay.name,
        email: stay.email || "",
        checkinDate:
          stay.checkinDate ? DateTime.fromJSDate(stay.checkinDate.toDate()) : undefined,
        checkoutDate:
          stay.checkoutDate ? DateTime.fromJSDate(stay.checkoutDate.toDate()) : undefined ,
        roomNumber: stay.roomNumber || "",
      });
    }
  }, [stayId, stay]);


  const onSubmit = async (values: FormValues) => {
    const checkIn = (values.checkinDate as DateTime).toJSDate();
    const checkOut = values.checkoutDate ? (values.checkoutDate as DateTime).toJSDate() : undefined;
    setIsCreating(true);
    let updatedStayId = stayId;
    if (stayId) {
      await updateStayCallable({
        projectId: projectId!,
        stayId: stayId,
        name: values.name,
        ...(values.email ? { email: values.email } : {}),
        ...(values.roomNumber ? { roomNumber: values.roomNumber } : {}),
        checkinDate: checkIn.toISOString(),
        checkoutDate: checkOut && checkOut.toISOString(),
        guestId: stayId,
      }).catch((e: any) => handleError(e));
    } else {
      await addStayCallable({
        projectId: projectId!,
        name: values.name,
        ...(values.email ? { email: values.email } : {}),
        ...(values.roomNumber ? { roomNumber: values.roomNumber } : {}),
        checkinDate: checkIn.toISOString(),
        checkoutDate: checkOut && checkOut.toISOString(),
      })
        .then(({ stayId }: { stayId: string }) => (updatedStayId = stayId))
        .catch((e: any) => handleError(e));
    }

    onCompleted && updatedStayId &&onCompleted(updatedStayId);

    setIsCreating(false);
  };

  if (isLoadingProject) return <LoadingPage />;
  if (!project) return <ErrorPage error={t("stay.form.project_not_found")} />;

  const isValid = formState.isValid;

  return (
      <Stack justifyContent="center" flex={1} spacing={4}>
        <Typography
          variant="h3"
          textAlign="center"
          sx={{
            fontWeight: "700",
            textTransform: "uppercase",
          }}
        >
          {stayId ? t("stay.form.update_guest_details") : t("stay.form.add_guest")}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction="column"
            justifyContent="center"
            flexWrap="wrap"
            gap={3}
            sx={{ padding: "10px" }}
          >
            <ControlledTextField
              required
              label={t("stay.form.name")}
              control={control}
              name={"name"}
            />
            <ControlledTextField
              label={t("stay.form.email")}
              control={control}
              name={"email"}
            />
            <ControlledTextField
              label={t("stay.form.room_number")}
              control={control}
              name={"roomNumber"}
            />
            <ControlledDatePicker
              required
              label={t("stay.form.check_in_date")}
              control={control}
              name={"checkinDate"}
            />
            <ControlledDatePicker
              required
              label={t("stay.form.check_out_date")}
              control={control}
              name={"checkoutDate"}
            />
            <Button
              variant="contained"
              color="success"
              size="large"
              type={"submit"}
              sx={{
                marginTop: "10px",
              }}
              disabled={isCreating || !isValid}
            >
              {isCreating ? (
                <CircularProgress color="primary" size={40} />
              ) : stayId ? (
                t("save")
              ) : (
                t("stay.form.add_guest")
              )}
            </Button>
          </Stack>
        </form>
      </Stack>
  );
};


