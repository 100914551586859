import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Container, Stack, Typography } from "@mui/material";
import { CreateProjectDefinition } from "@stai/types";
import { useTranslation } from "next-i18next";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledTextField } from "../../Common/components/forms/ControlledTextField";
import { createCallable } from "@stai/common";

export const createProjectCallable = createCallable(CreateProjectDefinition);

const FormValues = z.object({
  name: z.string().nonempty(),
});

export type FormValues = z.infer<typeof FormValues>;

export const ProjectCreatePage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      name: "",
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const { projectId } = await createProjectCallable({
          name: formValues.name,
        });
        navigate(`/projects/${projectId}`);
        toast.success(t("saved"));
      } catch (error) {
        handleError(error);
      }
    },
    [navigate]
  );

  const isLoading = formState.isSubmitting;

  return (
    <Container maxWidth={"md"}>
      <Stack p={3} spacing={3} flex={1}>
        <Typography variant="ah2">Create Project</Typography>
        <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
          <ControlledTextField
            control={control}
            label={t("name")}
            name={"name"}
            autoComplete="off"
          />
          <Button
            size="large"
            type="submit"
            variant="contained"
            sx={{ alignSelf: "flex-start" }}
            disabled={isLoading}
          >
            {t("create")}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};
