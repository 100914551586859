import { zodResolver } from "@hookform/resolvers/zod";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Divider, Paper, Stack, TextField } from "@mui/material";
import { createCallable } from "@stai/common";
import { DeleteWhatsappIntegrationDefinition, UpdateWhatsappIntegrationDefinition } from "@stai/types";
import { WhatsappIntegration } from "@stai/types/types/WhatsappIntegration";
import { useTranslation } from "next-i18next";
import { FC, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { ControlledTextField } from "../../../Common/components/forms/ControlledTextField";
import { handleError } from "../../../Common/helpers/handleError";
import DeleteIcon from "@mui/icons-material/Delete";

const updateWhatsappIntegrationCallable = createCallable(
  UpdateWhatsappIntegrationDefinition
);

const deleteWhatsappIntegrationCallable = createCallable(
  DeleteWhatsappIntegrationDefinition
);


const FormValues = z.object({
  phoneId: z.string(),
  apiKey: z.string(),
  verificationKey: z.string(),
  WABAID: z.string(),
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  projectId: string;
  whatappIntegration?: WhatsappIntegration;
}

export const WhatsAppForm: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { whatappIntegration: whatappIntegration } = props;

  const { control, handleSubmit, formState, reset } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
  });

  useEffect(() => {
    reset({
      apiKey: whatappIntegration?.apiKey, 
      phoneId: whatappIntegration?.phoneId, 
      verificationKey: whatappIntegration?.verificationKey, 
      WABAID: whatappIntegration?.WABAID, 
    });
  }, [whatappIntegration]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await updateWhatsappIntegrationCallable({
          projectId: props.projectId,
          apiKey: formValues.apiKey,
          phoneId: formValues.phoneId,
          verificationKey: formValues.verificationKey,
          WABAID: formValues.WABAID,
        });

        toast.success(t("saved"));
      } catch (error) {
        handleError(error);
      }
    },
    []
  );


  const onDelete = useCallback(async () => {
    try {
      await deleteWhatsappIntegrationCallable({
        projectId: props.projectId,
      });
      toast.success(t("deleted"));
    } catch (error) {
      handleError(error);
    }
  }, [props.projectId]);

  const isLoading = formState.isSubmitting;
  const isValid = formState.isValid;

  return (
    <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack component={Paper} p={2} spacing={3}>
        <ControlledTextField
          control={control}
          label={t("whatsapp.form.phoneId")}
          name="phoneId"
          autoComplete="off"
        />
        <ControlledTextField
          control={control}
          label={t("whatsapp.form.apiKey")}
          name="apiKey"
          autoComplete="off"
        />
        <ControlledTextField
          control={control}
          label={t("whatsapp.form.verificationKey")}
          name="verificationKey"
          autoComplete="off"
        />
        <ControlledTextField
          control={control}
          label={t("whatsapp.form.wabaId")}
          name="WABAID"
          autoComplete="off"
        />
        <Divider />
        <TextField label={t("whatsapp.form.phoneNumber")} value={whatappIntegration?.phoneNumber || ""} disabled />
        <TextField label={t("whatsapp.form.webhookUrl")} value={whatappIntegration?.webhookUrl || ""} disabled />
        
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button
          size="large"
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          disabled={isLoading || !isValid}
        >
          {t("save")}
        </Button>
        <Button
          size="large"
          variant="outlined"
          startIcon={<DeleteIcon />}
          disabled={isLoading}
          onClick={onDelete}
        >
          {t("delete")}
        </Button>
      </Stack>
    </Stack>
  );
};
