import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Chip, Paper, Stack, Typography } from "@mui/material";
import { createCallable } from "@stai/common";
import {
  AppConfig,
  PROMPT_CONTEXT_KEYS,
  UpdateAppConfigDefinition,
} from "@stai/types";
import { useTranslation } from "next-i18next";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { z } from "zod";
import { ControlledTextField } from "../../../Common/components/forms/ControlledTextField";
import { handleError } from "../../../Common/helpers/handleError";

const updateAppConfigCallable = createCallable(UpdateAppConfigDefinition);

const FormValues = z.object({
  projectPromptTemplate: z.string().min(10),
  globalPromptTemplate: z.string(),
  translatePromptTemplate: z.string(),
  attachmentToolPromptTemplate: z.string(),
  staffNotificationPromptTemplate: z.string(),
  chatHistoryMessageLimit: z.number(),
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  appConfig: AppConfig;
}

export const PrompstForm: FC<Props> = (props) => {
  const { appConfig } = props;
  const { t } = useTranslation();

  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      projectPromptTemplate: appConfig.projectPromptTemplate,
      globalPromptTemplate: appConfig.globalPromptTemplate || "",
      chatHistoryMessageLimit: appConfig.chatHistoryMessageLimit || 10,
      translatePromptTemplate: appConfig.translatePromptTemplate || "",
      attachmentToolPromptTemplate:
        appConfig.attachmentToolPromptTemplate || "",
      staffNotificationPromptTemplate: appConfig.staffBotToolPromptTemplate || "",
    },
  });

  const onSubmit = useCallback(async (formValues: FormValues) => {
    try {
      await updateAppConfigCallable({
        chatPromptTemplate: formValues.projectPromptTemplate,
        globalPrompt: formValues.globalPromptTemplate,
        translatePromptTemplate: formValues.translatePromptTemplate,
        attachmentToolPromptTemplate: formValues.attachmentToolPromptTemplate,
        chatPromptMessageLimit: Number(formValues.chatHistoryMessageLimit),
      });
      toast.success(t("saved"));
    } catch (error) {
      handleError(error);
    }
  }, []);

  const isLoading = formState.isSubmitting;

  return (
    <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="h6">Global Prompt Template</Typography>
        <ControlledTextField
          control={control}
          label="Global Promps"
          name="globalPromptTemplate"
          autoComplete="off"
          multiline
        />
        <Stack direction="column" spacing={1}>
          <Typography>
            This promp is used globaly in every project as first system message
          </Typography>
          <Stack direction="row" spacing={1}>
            {PROMPT_CONTEXT_KEYS.map((placeholder) => (
              <Chip key={placeholder} label={`{{${placeholder}}}`} />
            ))}
          </Stack>
        </Stack>
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="h6">Defalt Project Template</Typography>
        <ControlledTextField
          control={control}
          label="Default Prompt Template"
          name="projectPromptTemplate"
          autoComplete="off"
          multiline
        />
        <Stack direction="column" spacing={1}>
          <Typography>This promp is when creating a new project</Typography>
          <Stack direction="row" spacing={1}>
            {PROMPT_CONTEXT_KEYS.map((placeholder) => (
              <Chip key={placeholder} label={`{{${placeholder}}}`} />
            ))}
          </Stack>
        </Stack>
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="h6">Translate Prompt Template</Typography>
        <ControlledTextField
          control={control}
          label="Translate prompt template"
          name="translatePromptTemplate"
          autoComplete="off"
          multiline
        />
        <Stack direction="row" spacing={1}>
          <Typography>
            This template defines a prompt to translate a message.
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          {["language", "message"].map((placeholder) => (
            <Chip key={placeholder} label={`{{${placeholder}}}`} />
          ))}
        </Stack>
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="h6">Attachment tool Template</Typography>
        <ControlledTextField
          control={control}
          label="Attachment tool template"
          name="attachmentToolPromptTemplate"
          multiline
          autoComplete="off"
        />
        <Stack direction="row" spacing={1}>
          <Typography>
            This template defines a tool description of an attachment tool.
          </Typography>
        </Stack>
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="h6">Stuff notifications prompt</Typography>
        <ControlledTextField
          control={control}
          label="Stuff notifications prompt"
          name="staffNotificationPromptTemplate"
          multiline
          autoComplete="off"
        />
        <Stack direction="row" spacing={1}>
          <Typography>
          It is concatinated with prompt defined in the project
          </Typography>
        </Stack>
      </Stack>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="h6">Chat History Lenght</Typography>
        <ControlledTextField
          type="number"
          control={control}
          label="History length"
          name="chatHistoryMessageLimit"
          autoComplete="off"
        />
        <Stack direction="row" spacing={1}>
          <Typography>
            This setting defines how many latest chat messages are provided to
            llm.
          </Typography>
        </Stack>
      </Stack>
      <Button
        size="large"
        type="submit"
        variant="contained"
        sx={{ alignSelf: "flex-start" }}
        disabled={isLoading}
      >
        {t("save")}
      </Button>
    </Stack>
  );
};
