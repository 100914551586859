import { zodResolver } from "@hookform/resolvers/zod";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import {
  Button,
  Chip,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { createCallable } from "@stai/common";
import {
  CreateInvitationDefinition,
  DeleteInvitationDefinition,
  Invitation,
  RemoveManagerDefinition,
  User,
} from "@stai/types";
import { DateTime } from "luxon";
import { useTranslation } from "next-i18next";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { handleError } from "../../../Common/helpers/handleError";
import { ControlledTextField } from "../../../Common/components/forms/ControlledTextField";

const createInvitationCallable = createCallable(CreateInvitationDefinition);
const deleteInvitationCallable = createCallable(DeleteInvitationDefinition);
const removeManagerFromProjectCallable = createCallable(
  RemoveManagerDefinition
);
interface Props {
  projectId: string;
  users: User[];
  invitations: Invitation[];
}

/**
 * Credits: https://github.com/colinhacks/zod/issues/653#issuecomment-930293063
 */
const emailSchema = z.preprocess(
  (u: unknown) => (typeof u === "string" ? u.trim().toLowerCase() : u),
  z.string().email()
);

const FormValues = z.object({
  email: emailSchema,
});

type FormValues = z.infer<typeof FormValues>;

export const ManagersForm: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { projectId, invitations, users } = props;

  const { control, handleSubmit, formState, reset } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: { email: "" },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const { email } = formValues;

        await createInvitationCallable({
          projectId,
          email,
        });

        reset();
      } catch (error) {
        handleError(error);
      }
    },
    [reset, projectId]
  );

  const copyUrl = async (url: string) => {
    try {
      await navigator.clipboard.writeText(url);
      toast.success("Copied URL to clipboard.");
    } catch (error) {
      handleError(error);
    }
  };

  const removeManagerFromProject = async (userId: string) => {
    try {
      await removeManagerFromProjectCallable({
        projectId,
        userId,
      });
      toast.success("Manager was removed.");
    } catch (error) {
      handleError(error);
    }
  };

  const deleteInvitation = async (invitationId: string) => {
    try {
      await deleteInvitationCallable({
        projectId,
        invitationId,
      });
      toast.success("Manager was removed.");
    } catch (error) {
      handleError(error);
    }
  };

  const isLoading = formState.isSubmitting;

  const invitationsFiltered = invitations.filter((invitation) => !users.find(user => user.id === invitation.userId));

  return (
    <Stack spacing={3}>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">{t("managers")}</Typography>
        <Stack spacing={1} alignItems="flex-start">
          {!users.length && (
            <Typography>
              <em>{t("noManagers")}</em>
            </Typography>
          )}
          {users.map((user) => (
            <Stack
              key={user.id}
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography>Email:</Typography>
                <Chip key={user.id} label={user.email} />
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography>Registered:</Typography>
                <Chip
                  label={DateTime.fromJSDate(
                    user.createdAt.toDate()
                  ).toISODate()}
                />
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography>{t("role")}</Typography>
                <Chip label={user.role} />
              </Stack>
              <Tooltip title={t("removeManager")} placement="top">
                <IconButton
                  size="large"
                  onClick={() => removeManagerFromProject(user.id)}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Stack>
          ))}
        </Stack>
        <Typography variant="ah3">{t("pendingInvitations")}</Typography>
        <Stack spacing={1} alignItems="flex-start">
          {!invitationsFiltered.length && (
            <Typography>
              <em>{t("noInvitation")}</em>
            </Typography>
          )}
          {invitationsFiltered.map((invitation) => (
            <Stack
              key={invitation.id}
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography>Email:</Typography>
                <Chip key={invitation.id} label={invitation.email} />
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                {invitation.userId && invitation.acceptedAt ? (
                  <>
                    <Typography>{t("acceptedOn")}</Typography>
                    <Chip
                      label={DateTime.fromJSDate(
                        invitation.acceptedAt.toDate()
                      ).toISODate()}
                    />
                  </>
                ) : (
                  <>
                    <Typography>{t("invitedOn")}</Typography>
                    <Chip
                      label={DateTime.fromJSDate(
                        invitation.createdAt.toDate()
                      ).toISODate()}
                    />
                  </>
                )}
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography>{t("invitationLink")}</Typography>
                <IconButton
                  size="large"
                  onClick={() => copyUrl(invitation.invitationLink)}
                >
                  <ContentCopyIcon />
                  <Typography>{t("copy")}</Typography>
                </IconButton>
              </Stack>
              <Tooltip title={t("removeInvitation")} placement="top">
                <IconButton
                  size="large"
                  onClick={() => deleteInvitation(invitation.id)}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Stack>
          ))}
        </Stack>
        <Typography variant="ah3">{t("sendInvitation")}</Typography>
        <Stack
          spacing={2}
          component="form"
          direction="row"
          onSubmit={handleSubmit(onSubmit)}
        >
          <ControlledTextField
            name={"email"}
            control={control}
            disabled={isLoading}
            placeholder={t("email")}
            fullWidth
          />
          <Button
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
            startIcon={<SendIcon />}
          >
            {t("invite")}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
