import { zodResolver } from "@hookform/resolvers/zod";
import { Add } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { createCallable } from "@stai/common";
import {
  AddKnowledgeConfigDefinition,
  DeleteKnowledgeConfigDefinition,
  KnowledgeConfig,
  UpdateKnowledgeConfigDefinition,
} from "@stai/types";
import { nanoid } from "nanoid";
import { useTranslation } from "next-i18next";
import { FC, useCallback, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../../Common/helpers/handleError";
import { ControlledSwitch } from "../../../Common/components/forms/ControlledSwitch";
import { ControlledTextField } from "../../../Common/components/forms/ControlledTextField";
import { FormValues } from "./FormValues";

const addKnowledgeConfigCallable = createCallable(AddKnowledgeConfigDefinition);
const deleteKnowledgeConfigCallable = createCallable(
  DeleteKnowledgeConfigDefinition
);
const updateKnowledgeConfigCallable = createCallable(
  UpdateKnowledgeConfigDefinition
);

interface Props {
  projectId: string;
  knowledgeConfig: Partial<KnowledgeConfig>;
}

function defaultValues(knowledgeConfig: Partial<KnowledgeConfig>) {
  return {
    title: knowledgeConfig.title,
    description: knowledgeConfig.description,
    enabled: knowledgeConfig.enabled || true,
    useEmbeddings: knowledgeConfig.useEmbeddings || false,
    documents: knowledgeConfig.documents,
    asText: false,
    textContent:
      knowledgeConfig.documents?.map((d: any) => d.text).join("\n\n") || "",
  };
}

export const KnowledgeForm: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { projectId, knowledgeConfig } = props;

  const isCreating = !knowledgeConfig.id;

  const navigate = useNavigate();
  const { control, handleSubmit, formState, watch, reset } =
    useForm<FormValues>({
      resolver: zodResolver(FormValues),
      defaultValues: defaultValues(knowledgeConfig),
    });

  useEffect(() => {
    reset(defaultValues(knowledgeConfig));
  }, [knowledgeConfig]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      let documents = formValues.documents;
      if (formValues.asText) {
        documents =
          formValues.textContent?.split("\n\n").map((text) => ({
            id: nanoid(),
            text,
          })) || [];
      }

      try {
        if (knowledgeConfig.id) {
          await updateKnowledgeConfigCallable({
            knowledgeId: knowledgeConfig.id,
            projectId: projectId,
            title: formValues.title,
            description: formValues.description,
            enabled: formValues.enabled,
            useEmbeddings: formValues.useEmbeddings || false,
            documents: documents,
          });
        } else {
          await addKnowledgeConfigCallable({
            projectId: projectId,
            title: formValues.title,
            description: formValues.description,
            enabled: formValues.enabled,
            useEmbeddings: formValues.useEmbeddings || false,
            documents: documents,
          }).then(({ knowledgeId }) => {
            reset();
            console.log("knowledgeId", knowledgeId);
            navigate(`../${knowledgeId}`);
          });
        }
        reset(formValues);
        toast.success(t("saved"));
      } catch (error) {
        toast.success("Error!");
        handleError(error);
      }
    },
    [projectId, knowledgeConfig]
  );

  const [isDeleting, setIsDeleting] = useState(false);
  const deleteKnowledge = () => {
    if (!knowledgeConfig.id) {
      return;
    }
    setIsDeleting(true);
    deleteKnowledgeConfigCallable({
      knowledgeId: knowledgeConfig.id,
      projectId: projectId,
    }).finally(() => {
      setIsDeleting(false);
      navigate("..");
    });
  };

  const useEmbeddings = watch("useEmbeddings");
  const asText = watch("asText");
  const documents = useFieldArray({
    control,
    name: "documents",
  });

  const addDocument = () => {
    documents.append({
      id: nanoid(),
      text: "",
    });
  };

  const removeDocument = (index: number) => {
    documents.remove(index);
  };

  const isUpdating = formState.isSubmitting;
  const isValid = formState.isValid;

  return (
    <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack component={Paper} p={2} spacing={3}>
        <ControlledTextField
          control={control}
          label={t("toolName")}
          name="title"
          autoComplete="off"
          disabled={isUpdating}
        />
        <ControlledTextField
          control={control}
          label={t("toolDescription")}
          name="description"
          autoComplete="off"
          disabled={isUpdating}
        />
        <Typography variant="caption">
          {t("knowledgeNameDescription")}
        </Typography>

        <Stack direction="column" spacing={1.5}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="body1">{t("content")}</Typography>
            <Tooltip
              title="It only defines how you input the data"
              placement="top"
            >
              {/* div is required for tooltip */}
              <div>
                <ControlledSwitch
                  sx={{ paddingLeft: "20px" }}
                  control={control}
                  label={asText ? t("asTextSwitch") : t("asListSwitch")}
                  name="asText"
                />
              </div>
            </Tooltip>
          </Stack>
          {asText ? (
            <>
              <Typography variant="caption">
                Separate each knowledge by an extra new line (\n\n)
              </Typography>
              <ControlledTextField
                label={t("knowledgeContents")}
                name="textContent"
                disabled={false}
                fullWidth
                multiline
                rows={8}
                control={control}
              />
            </>
          ) : (
            <Stack spacing={2}>
              {documents.fields.map((document, i) => (
                <Stack direction="row" key={document.id} alignItems="center">
                  <ControlledTextField
                    name={`documents.${i}.text`}
                    defaultValue={document.text}
                    fullWidth
                    multiline
                    rows={3}
                    control={control}
                  />
                  <Tooltip title={t("deleteDocument")}>
                    <IconButton size="large" onClick={() => removeDocument(i)}>
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              ))}
              <IconButton onClick={addDocument}>
                <Add />
              </IconButton>
            </Stack>
          )}
        </Stack>

        <Stack
          spacing={3}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={6} alignItems="center">
            <Button
              size="large"
              variant="contained"
              sx={{ alignSelf: "flex-start" }}
              disabled={isUpdating || !isValid}
              onClick={handleSubmit(onSubmit)}
            >
              {isCreating ? t("create") : t("update")}
            </Button>

            <Tooltip
              title="Agent can return the whole content of the knowledge base or make an semantic search in it and return only relevant documents"
              placement="top"
            >
              {/* div is required for tooltip */}
              <div>
                <ControlledSwitch
                  control={control}
                  label={useEmbeddings ? t("embeddings") : t("wholeFileS")}
                  name="useEmbeddings"
                />
              </div>
            </Tooltip>
            <Tooltip
              title="When disabled this knowledge will not be provided to LLM"
              placement="top"
            >
              {/* div is required for tooltip */}
              <div>
                <ControlledSwitch
                  control={control}
                  label={t("enabled")}
                  name={"enabled"}
                />
              </div>
            </Tooltip>
            {/* <ControlledSwitch control={control} label="as text" name="asText" /> */}
            {knowledgeConfig.indexingCompleted == false && (
              <Chip label={t("indexingInProgress")} color="warning" />
            )}
            {knowledgeConfig.indexingCompleted &&
              !knowledgeConfig.indexingError && (
                <Chip label={t("indexingCompleted")} color="success" />
              )}
            {knowledgeConfig.indexingError && (
              <>
                <Chip label={t("indexingFailed")} color={"error"} />
                <Typography variant="body2">{t("indexingError")}</Typography>
              </>
            )}
          </Stack>

          {isDeleting && <CircularProgress />}
          {!isDeleting && !isCreating && (
            <Tooltip title={t("deleteKnowledgeBase")} placement="top">
              <IconButton size="large" onClick={deleteKnowledge}>
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
