import { zodResolver } from "@hookform/resolvers/zod";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Divider, Paper, Stack, TextField, Typography } from "@mui/material";
import { createCallable } from "@stai/common";
import {
  DeleteTelegramIntegrationDefinition,
  ProjectConfig,
  UpdateTelegramIntegrationDefinition,
} from "@stai/types";
import { useTranslation } from "next-i18next";
import { FC, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { handleError } from "../../../Common/helpers/handleError";
import { ControlledTextField } from "../../../Common/components/forms/ControlledTextField";

const updateTelegramIntegrationCallable = createCallable(
  UpdateTelegramIntegrationDefinition
);

const deleteTelegramIntegrationCallable = createCallable(
  DeleteTelegramIntegrationDefinition
);


const FormValues = z.object({
  telegramApiKey: z.string().min(1),
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  projectConfig: ProjectConfig;
}

export const TelegramForm: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { projectConfig } = props;

  const defaultValues = {
    telegramApiKey: projectConfig.telegramApiKey || "",
  }

  const { control, handleSubmit, formState, reset } = useForm<FormValues>({
    defaultValues: defaultValues,
    resolver: zodResolver(FormValues),
  });

  useEffect(() => {
    reset(defaultValues);
  }, [projectConfig]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await updateTelegramIntegrationCallable({
          projectId: projectConfig.id,
          telegramApiKey: formValues.telegramApiKey,
        });

        toast.success(t("saved"));
      } catch (error) {
        handleError(error);
      }
    },
    [projectConfig]
  );

  const onDelete = useCallback(async () => {
    try {
      await deleteTelegramIntegrationCallable({
        projectId: projectConfig.id,
      });
      toast.success(t("deleted"));
    } catch (error) {
      handleError(error);
    }
  }, [projectConfig]);

  const isLoading = formState.isSubmitting;
  const isValid = formState.isValid;

  return (
    <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack component={Paper} p={2} spacing={3}>
        <ControlledTextField
          control={control}
          label={t("telegram.form.apiKey")}
          name="telegramApiKey"
          autoComplete="off"
        />
        <Divider />
        <TextField label={t("telegram.form.botName")} value={projectConfig.telegramBotName || ""} disabled />
        <TextField label={t("telegram.form.webhookUrl")} value={projectConfig.telegramWebhookUrl || ""} disabled />
      </Stack>
      <Stack direction="row" justifyContent={"space-between"} spacing={2}>
        <Button
          size="large"
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          disabled={isLoading || !isValid}
        >
          {t("save")}
        </Button>
        <Button
          size="large"
          variant="outlined"
          startIcon={<DeleteIcon />}
          disabled={isLoading}
          onClick={onDelete}
        >
          {t("delete")}
        </Button>
      </Stack>
    </Stack>
  );
};
