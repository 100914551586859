import WhatsApp from "@mui/icons-material/WhatsApp";
import {
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";
import { auth } from "@stai/common";
import {
  Category,
  Drop,
  Logout,
  Profile,
  Text,
} from "iconsax-react";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import { STAI_SVG_LOGO_PATH_WHITE } from "../../Common/assets";
import { SideBar } from "../../Common/components/sidebar/SideBar";
import { SideBarList } from "../../Common/components/sidebar/SideBarList";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { Telegram } from "@mui/icons-material";

export const SuperadminSidebarView: FC = () => {
  const { t } = useTranslation();

  return (
    <SideBar anchor="left" variant="persistent" open className={"darken"}>
      <Stack
        padding={3}
        direction="column"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <img src={STAI_SVG_LOGO_PATH_WHITE} width={150} />
        <Typography textAlign="center">SUPER ADMIN CONSOLE</Typography>
      </Stack>
      <SideBarList>
        <ListItemButton
          to={`/superadmin/prompts`}
          component={NavLink}
        >
          <ListItemIcon>
            <Text />
          </ListItemIcon>
          <ListItemText primary="Promts" />
        </ListItemButton>

        <ListItemButton
          to={`/superadmin/users`}
          component={NavLink}
        >
          <ListItemIcon>
            <Profile />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItemButton>

        <ListItemButton
          to={`/superadmin/whatsapp/shared-integration`}
          component={NavLink}
        >
          <ListItemIcon>
            <WhatsApp />
          </ListItemIcon>
          <ListItemText primary="Whatsapp" />

        </ListItemButton>
        <ListItemButton
          to={`/superadmin/staff-bot`}
          component={NavLink}
        >
          <ListItemIcon>
            <Telegram />
          </ListItemIcon>
          <ListItemText primary="Staff bot" />
        </ListItemButton>

      </SideBarList>
      <Stack flex={1} />
      <SideBarList>
        {ENVIRONMENT.nodeEnv === "development" && (
          <ListItemButton
            component="a"
            target="_blank"
            href="http://localhost:4000"
          >
            <ListItemIcon>
              <Drop />
            </ListItemIcon>
            <ListItemText primary={t("emulator")} />
          </ListItemButton>
        )}

        <ListItemButton to="/superadmin/projects/select" component={NavLink}>
          <ListItemIcon>
            <Category />
          </ListItemIcon>
          <ListItemText primary={t("projects")} />
        </ListItemButton>
        <ListItemButton onClick={() => void auth.signOut()}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText primary={t("logout")} />
        </ListItemButton>
      </SideBarList>
    </SideBar>
  );
};
