import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Container,
  InputAdornment,
  List,
  ListItem,
  Paper,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { Project } from "@stai/types";
import { useTranslation } from "next-i18next";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorView } from "../../Common/views/ErrorView";
import { LoadingView } from "../../Common/views/LoadingView";
import { ProjectPicture } from '../../Common/views/ProjectPicture';
import { useProjects } from '../hooks/useProjects';


const ProjectButton = ({ project }: { project: Project }) => {
  return (
    <Stack
      direction="row"
      component={Paper}
      width={"100%"}
      spacing={10}
      padding={4}
      alignItems="center"
      sx={{
        border: "1px solid black",
      }}
    >
      <ProjectPicture projectId={project.id} />
      <Button variant="outlined" size="large" fullWidth>
        {project.name}
      </Button>
    </Stack>
  );
};

export const SuperadminProjectSelectPage: FC = () => {
  const { t } = useTranslation();

  const [projects, projectsLoading, projectsErrors] = useProjects();
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const isLoading = projectsLoading;
  const error = projectsErrors;

  const selectProject = (projectId: string) => {
    navigate(`/projects/${projectId}`);
  };

  return (
    <Container maxWidth={"md"}>
      <Stack px={3} pt={3} pb={1} spacing={2} gap={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="ah2">{t("selectProject")}</Typography>
        </Stack>
        <Stack gap={2} direction={{ xs: "column", sm: "row" }}>
          <TextField
            value={searchQuery}
            label={t("search")}
            onChange={(event) => setSearchQuery(event.target.value)}
            autoComplete="off"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        {isLoading && <LoadingView />}
        {error && <ErrorView error={error} />}
      </Stack>
      {!isLoading && (
        <List>
          {projects
            .filter((project) =>
              searchQuery
                ? project.name
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
                : true
            )
            .map((project) => (
              <ListItem
                key={project.id}
                onClick={() => selectProject(project.id)}
              >
                <ProjectButton project={project} />
              </ListItem>
            ))}
        </List>
      )}
    </Container>
  );
};
