import { Stack } from "@mui/material";
import { useIsSuperAdmin, useMyUserRecord } from "@stai/common";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { ErrorPage } from "../../Common/views/ErrorPage";
import { SuperadminSidebarView } from "./SuperadminSidebarView";
import { MAIN_SIDEBAR_WIDTH } from "../../Common/components/sidebar/SideBar";

export const SuperadminLayout: FC = () => {
  const {user} = useMyUserRecord();
  const isSuperAdmin = useIsSuperAdmin();
  if(!isSuperAdmin) {
    return <ErrorPage error={new Error(`User ${user?.email} is not SUPERADMIN`)} />; 
  }

  return (
    <Stack sx={{ minHeight: "100%" }}>
      <SuperadminSidebarView />
      <Stack ml={`${MAIN_SIDEBAR_WIDTH}px`} flex={1}>
        <Outlet />
      </Stack>
    </Stack>
  );
};
