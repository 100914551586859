import GoogleIcon from "@mui/icons-material/Google";
import { Button, Stack } from "@mui/material";
import { SignInMethod, auth, authProviderFactory } from "@stai/common";
import { signInWithRedirect } from "firebase/auth";
import { useTranslation } from "next-i18next";
import { FC, useCallback } from "react";
import { handleError } from "../../helpers/handleError";
import { FirebaseAuthCaptionView } from "./FirebaseAuthCaptionView";
import { MainContainer } from "../../../Main/views/MainContainer";

export const FirebaseAuthSignInPage: FC = () => {
  const { t } = useTranslation();

  const signIn = useCallback(
    async (signInMethod: SignInMethod) => {
      const provider = authProviderFactory(signInMethod);
      signInWithRedirect(auth, provider)
        .catch(async function (e) {
          if (e.code == "auth/popup-blocked") {
            console.error("Sign in with popup failed. Trying with redirect");
            return await signInWithRedirect(auth, provider);
          } else {
            console.error("Sign in error", e);
            handleError(e);
          }
        })
        .then((result) => {
          console.log("Sign in result", result);
        });
    },
    [auth, authProviderFactory]
  );

  return (
    <MainContainer>
      <Stack flex={1} p={2} justifyContent="center" spacing={4} sx={{}}>
        <Stack spacing={2}>
          <FirebaseAuthCaptionView />
        </Stack>

        <Stack spacing={1.5}>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            startIcon={<GoogleIcon sx={{ width: 28, height: 28 }} />}
            onClick={() => signIn("google.com")}
          >
            {t("continueWithGoogle")}
          </Button>
        </Stack>
      </Stack>
    </MainContainer>
  );
};
