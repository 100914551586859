import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from "@mui/material";
import { auth, useIsSuperAdmin } from "@stai/common";
import {
  ArrangeHorizontalSquare,
  Book1,
  Category,
  ChartSuccess,
  CpuSetting,
  Logout,
  Messages3,
  Paperclip,
  Personalcard,
  Setting2,
  ShieldSecurity,
  UserSquare,
  Magicpen,
  TagUser
} from "iconsax-react";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { NavLink, useParams } from "react-router-dom";
import { SideBar } from "../../Common/components/sidebar/SideBar";
import { SideBarList } from "../../Common/components/sidebar/SideBarList";
import { ProjectPicture } from "../../Common/views/ProjectPicture";
import { useProject } from "../hooks/useProject";


export const ProjectSidebarView: FC = () => {
  const { projectId } = useParams<"projectId">();
  const [project] = useProject(projectId);
  const { t } = useTranslation();
  const isSuperAdmin = useIsSuperAdmin()

  return (
    <SideBar anchor="left" variant="persistent" open className={"darken"}>
      {project && (
        <Stack
          padding={3}
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <ProjectPicture projectId={project.id} />
          <Typography textAlign="center">{project.name}</Typography>
        </Stack>
      )}
      <SideBarList>
        <ListItemButton
          to={`/projects/${projectId}/knowledge`}
          component={NavLink}
        >
          <ListItemIcon>
            <Book1 />
          </ListItemIcon>
          <ListItemText primary={t("menu.knowledge")} />
        </ListItemButton>
        <ListItemButton
          to={`/projects/${projectId}/attachments`}
          component={NavLink}
        >
          <ListItemIcon>
            <Paperclip />
          </ListItemIcon>
          <ListItemText primary={t("menu.attachments")} />
        </ListItemButton>
        <ListItemButton
          to={`/projects/${projectId}/integrations`}
          component={NavLink}
        >
          <ListItemIcon>
            <CpuSetting />
          </ListItemIcon>
          <ListItemText primary={t("menu.integrations")} />
        </ListItemButton>
        <ListItemButton to={`/projects/${projectId}/tools`} component={NavLink}>
          <ListItemIcon>
            <ArrangeHorizontalSquare />
          </ListItemIcon>
          <ListItemText primary={t("menu.apiTools")} />
        </ListItemButton>
        <ListItemButton
          to={`/projects/${projectId}/staff-notifications`}
          component={NavLink}
        >
          <ListItemIcon>
            <TagUser />
          </ListItemIcon>
          <ListItemText primary={t("menu.staffNotifications")} />
        </ListItemButton>
        <ListItemButton
          to={`/projects/${projectId}/phases`}
          component={NavLink}
        >
          <ListItemIcon>
            <ChartSuccess />
          </ListItemIcon>
          <ListItemText primary={t("menu.phases")} />
        </ListItemButton>

        <ListItemButton to={`/projects/${projectId}/chats`} component={NavLink}>
          <ListItemIcon>
            <Messages3 />
          </ListItemIcon>
          <ListItemText primary={t("menu.chats")} />
        </ListItemButton>
        <ListItemButton
          to={`/projects/${projectId}/guests`}
          component={NavLink}
        >
          <ListItemIcon>
            <Personalcard />
          </ListItemIcon>
          <ListItemText primary={t("menu.guests")} />
        </ListItemButton>

        <ListItemButton
          to={`projects/${projectId}/try`}
          component={NavLink}
        >
          <ListItemIcon>
            <Magicpen />
          </ListItemIcon>
          <ListItemText primary={t("menu.try")} />
        </ListItemButton>
        
      </SideBarList>
      <Stack flex={1} />
      <SideBarList>
        {isSuperAdmin && (
          <ListItemButton
            component={NavLink}
            to="/superadmin/prompts"
          >
            <ListItemIcon>
              <ShieldSecurity />
            </ListItemIcon>
            <ListItemText primary="Superadmin" />
          </ListItemButton>
        )}
        <ListItemButton
          to={`/projects/${projectId}/managers`}
          component={NavLink}
        >
          <ListItemIcon>
            <UserSquare />
          </ListItemIcon>
          <ListItemText primary={t("menu.managers")} />
        </ListItemButton>
        <ListItemButton
          to={`/projects/${projectId}/settings`}
          component={NavLink}
        >
          <ListItemIcon>
            <Setting2 />
          </ListItemIcon>
          <ListItemText primary={t("menu.settings")} />
        </ListItemButton>
        <ListItemButton to="/projects/select" component={NavLink}>
          <ListItemIcon>
            <Category />
          </ListItemIcon>
          <ListItemText primary={t("menu.projects")} />
        </ListItemButton>
        <ListItemButton onClick={() => void auth.signOut()}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText primary={t("menu.logout")} />
        </ListItemButton>
      </SideBarList>
    </SideBar>
  );
};
