import { getCollectionRef, useValidCollectionData } from "@stai/common";
import { ApiToolConfig, ApiToolConfigsCollection } from "@stai/types";
import { orderBy, query } from "firebase/firestore";

export function useApiToolConfigs(projectId?: string) {
  return useValidCollectionData(
    projectId
      ? query(
          getCollectionRef(ApiToolConfigsCollection(projectId)),
          orderBy("createdAt", "asc")
        )
      : null,
    ApiToolConfig
  );
}
