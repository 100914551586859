import { Dialog, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import { t } from "i18next";

type QRCodeDialogProps = {
  open: boolean;
  onClose: () => void;
  url: string;
};

export const QRCodeDialog = ({ open, onClose, url }: QRCodeDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <DialogTitle sx={{ textAlign: "center" }}>
        {t("try.page.scan_qr")}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Stack spacing={2} flexDirection="column" alignItems="center">
          <QRCode value={url} />
          <Link to={url} target="_blank" rel="noreferrer">link</Link>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};