import { zodResolver } from "@hookform/resolvers/zod";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Divider, Paper, Stack, TextField, Typography } from "@mui/material";
import { createCallable } from "@stai/common";
import { UpdateWhatsappSharedIntegrationDefinition } from "@stai/types";
import { WhatsappIntegration } from "@stai/types/types/WhatsappIntegration";
import { useTranslation } from "next-i18next";
import { FC, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { ControlledTextField } from "../../../Common/components/forms/ControlledTextField";
import { handleError } from "../../../Common/helpers/handleError";

const updateWhatsappSharedIntegrationCallable = createCallable(
  UpdateWhatsappSharedIntegrationDefinition
);


const FormValues = z.object({
  phoneId: z.string(),
  apiKey: z.string(),
  verificationKey: z.string(),
  WABAID: z.string(),
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  whatappSharedIntegration?: WhatsappIntegration;
}

export const WhatsappSharedForm: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { whatappSharedIntegration } = props;

  const { control, handleSubmit, formState, reset } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
  });

  useEffect(() => {
    reset({
      apiKey: whatappSharedIntegration?.apiKey, 
      phoneId: whatappSharedIntegration?.phoneId, 
      verificationKey: whatappSharedIntegration?.verificationKey, 
      WABAID: whatappSharedIntegration?.WABAID, 
    });
  }, [whatappSharedIntegration]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await updateWhatsappSharedIntegrationCallable({
          apiKey: formValues.apiKey,
          phoneId: formValues.phoneId,
          verificationKey: formValues.verificationKey,
          WABAID: formValues.WABAID,
        });

        toast.success(t("saved"));
      } catch (error) {
        handleError(error);
      }
    },
    []
  );


  return (
    <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack component={Paper} p={2} spacing={3}>
        <ControlledTextField
          control={control}
          label={t("whatsapp.form.phoneId")}
          name="phoneId"
          autoComplete="off"
        />
        <ControlledTextField
          control={control}
          label={t("whatsapp.form.apiKey")}
          name="apiKey"
          autoComplete="off"
        />
        <ControlledTextField
          control={control}
          label={t("whatsapp.form.verificationKey")}
          name="verificationKey"
          autoComplete="off"
        />
        <ControlledTextField
          control={control}
          label={t("whatsapp.form.wabaId")}
          name="WABAID"
          autoComplete="off"
        />
        <Divider />
        <TextField label={t("whatsapp.form.phoneNumber")} value={whatappSharedIntegration?.phoneNumber || ""} disabled />
        <TextField label={t("whatsapp.form.webhookUrl")} value={whatappSharedIntegration?.webhookUrl || ""} disabled />
        
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button
          size="large"
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
          disabled={formState.isSubmitting || !formState.isValid}
        >
          {t("save")}
        </Button>
      </Stack>
    </Stack>
  );
};
