import { Paper, Stack, Typography } from "@mui/material";
import { ApiToolConfig } from "@stai/types";
import { useTranslation } from "next-i18next";
import { useParams } from "react-router-dom";
import { ErrorPage } from "../../../Common/views/ErrorPage";
import { LoadingPage } from "../../../Common/views/LoadingPage";
import { ApiToolForm } from "./ApiToolForm";
import { useApiToolConfig } from "./hooks/useApiToolConfig";

//https://developer.atlassian.com/cloud/trello/rest/api-group-cards/#api-cards-post
const newApiTool: Partial<ApiToolConfig> = {
  id: undefined,
  name: "Create Trello cart",
  description: "This tool creates a card in Trello board",
  method: "POST",
  urlTemplate:
    "https://api.trello.com/1/cards?idList=ZZZZ60&key=XXXXX&token=YYYY",
  bodyTemplate:
    '{"name":"{{card_name}}", "desc":"{{card_description}}", "pos":"top"}',
  parameters: [
    {
      name: "card_name",
      type: "string",
      description: "Name of the card",
      required: true,
      testValue: "test card",
    },
    {
      name: "card_description",
      type: "string",
      description: "Description to add to the card",
      required: true,
      testValue: "test description",
    },
  ],
  headers: [
    {
      name: "Content-Type",
      value: "application/json",
    },
    {
      name: "Accept",
      value: "application/json",
    },
  ],
  responseTemplate:
    "The card was created. Find it via url {{response.shortUrl}}",
  enabled: true,
};

export const ApiToolPage = () => {
  const { t } = useTranslation();
  const { knowledgeId, projectId } = useParams<{
    knowledgeId: string;
    projectId: string;
  }>();

  const [loadedApiToolConfig, loading, error] = useApiToolConfig(
    projectId,
    knowledgeId
  );
  const apiToolConfig =
    knowledgeId == undefined ? newApiTool : loadedApiToolConfig;

  if (!apiToolConfig && error) {
    return <ErrorPage error={error} />;
  }

  if (loading || !apiToolConfig) {
    return <LoadingPage />;
  }

  return (
    <Stack padding={3} spacing={3}>
      <Stack component={Paper} p={2} spacing={3}>
        <Typography variant="ah3">{t("apitool.page.apiTool")}</Typography>
        <Typography variant="body1">{t("apitool.page.apiToolDescription")}</Typography>
      </Stack>
      <ApiToolForm projectId={projectId!} apiToolConfig={apiToolConfig} />
    </Stack>
  );
};
