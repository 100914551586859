import { Divider, Stack, TextField } from "@mui/material";
import { ChatPrompt, ChatPromptMessageType } from "@stai/types";
import { useTranslation } from "next-i18next";
import { FC } from "react";

interface Props {
  chatPrompt: ChatPrompt;
}

export const ChatPromptDialogTabInput: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { chatPrompt } = props;

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        <TextField
          value={chatPrompt.llmType}
          label={t("llmModel")}
          sx={{ textarea: { fontSize: 14 } }}
          fullWidth
        />
        <TextField
          value={chatPrompt.llmTemperature || "1"}
          label={t("llmTemperature")}
          sx={{ textarea: { fontSize: 14 } }}
          fullWidth
        />
      </Stack>
      <Divider>Global Prompt</Divider>
      <TextField
        value={chatPrompt.globalPrompt}
        sx={{ textarea: { fontSize: 14 } }}
        fullWidth
        multiline
      />
      <Divider>Project Prompt</Divider>
      <TextField
        value={chatPrompt.projectPrompt}
        sx={{ textarea: { fontSize: 14 } }}
        fullWidth
        multiline
      />
      <Divider>Error</Divider>
      <TextField
        value={chatPrompt.error}
        sx={{ textarea: { fontSize: 14 } }}
        fullWidth
        multiline
      />
      <Divider>{t("messages")}</Divider>
      {!!chatPrompt.messages &&
        chatPrompt.messages.map((message, index) => (
          <TextField
            key={index}
            value={message.text}
            label={toOpenAiLabel(message.type)}
            sx={{ textarea: { fontSize: 14 } }}
            fullWidth
            multiline
          />
        ))}
      <Divider>{t("userInput")}</Divider>
      <TextField
        value={chatPrompt.userInput}
        sx={{ textarea: { fontSize: 14 } }}
        fullWidth
        multiline
      />
    </Stack>
  );
};

function toOpenAiLabel(type: ChatPromptMessageType) {
  switch (type) {
    case "SYSTEM":
      return "System";
    case "USER":
      return "Human";
    case "AI":
      return "AI";
  }
}
