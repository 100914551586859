import { getDocRef, useValidDocumentData } from "@stai/common";
import {
  StaffNotificationConfig,
  StaffNotificationConfigCollection
} from "@stai/types";

export function useStaffNotificationConfig(projectId?: string) {
  if (!projectId) return [];
  return useValidDocumentData(
    getDocRef(StaffNotificationConfigCollection(projectId), "default"),
    StaffNotificationConfig
  );
}
