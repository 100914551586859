import { Box } from "@mui/material";
import { DragEvent, FC, PropsWithChildren, useCallback, useRef } from "react";

interface Props  {
  accept?: string;
  multiple?: boolean;
  disabled?: boolean;
  onSelect: (files: File[]) => void;
}

export const FileInput: FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { accept, multiple, disabled, onSelect } = props;

  const selectFiles = useCallback(
    (fileList: FileList | null) => {
      if (!fileList?.length) return;
      const files = Array.from(fileList);
      onSelect(files);
    },
    [onSelect]
  );

  // const onDragOver = (e: DragEvent) => {
  //   e.preventDefault();
  // };

  // const onDrop = (e: DragEvent) => {
  //   e.preventDefault();
  //   selectFiles(e.dataTransfer.files);
  // };

  return (
    <label
      style={{ cursor: "pointer" }}
      // onDragOver={onDragOver}
      // onDrop={onDrop}
    >
      <input
        ref={inputRef}
        type="file"
        hidden
        accept={accept}
        multiple={multiple}
        disabled={disabled}
        value={undefined} // uncontrolled, because its value can only be set by a user, and not programmatically.
        onChange={(e) => selectFiles(e.target.files)}
      />
      <Box onClick={() => inputRef.current?.click()}>{children as React.ReactNode}</Box>
    </label>
  );
};
