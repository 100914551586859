import { FC } from "react";
import { MainContainer } from "../../Main/views/MainContainer";
import { LoadingView } from "./LoadingView";

export const LoadingPage: FC = () => {
  return (
    <MainContainer>
      <LoadingView />
    </MainContainer>
  );
};
