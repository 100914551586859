import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { getFirestoreUrl } from "@stai/common";
import { ChatMessageCollection, ChatPromptsCollection } from "@stai/types";
import { useTranslation } from "next-i18next";
import { FC, useState } from "react";
import { ErrorView } from "../../Common/views/ErrorView";
import { LoadingView } from "../../Common/views/LoadingView";
import { useChatPrompt } from "../hooks/useChatPrompt";
import { useChatPromptDialog } from "../hooks/useChatPromptDialog";
import { ChatPromptDialogTabInput } from "./ChatPromptDialogTabInput";
import { ChatPromptDialogTabOutput } from "./ChatPromptDialogTabOutput";
import { ChatPromptDialogTabTools } from "./ChatPromptDialogTabTools";

type Tab = "INPUT" | "OUTPUT" | "TOOLS";

export const ChatPromptDialog: FC = () => {
  const { t } = useTranslation();
  const { isOpen, projectId, chatId, chatMessageId, close } =
    useChatPromptDialog();
  const [chatPrompt, isLoading, error] = useChatPrompt(
    projectId,
    chatId,
    chatMessageId
  );
  const [currentTab, setCurrentTab] = useState<Tab>("INPUT");

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      PaperProps={{
        sx: {
          width: 800,
          maxWidth: "100%",
          height: "95%",
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Stack>{t("chat.prompt.message.details")}</Stack>
          {/* {chatId && chatMessageId && (
            <Stack direction="row" spacing={2}>
              <Button
                component="a"
                target="_blank"
                href={getFirestoreUrl(
                  ChatMessageCollection(projectId, chatId),
                  chatMessageId
                )}
                startIcon={<ChatBubbleIcon />}
              >
                {t("chat.prompt.message")}
              </Button>
              <Button
                component="a"
                target="_blank"
                href={getFirestoreUrl(
                  ChatPromptsCollection(projectId, chatId),
                  chatMessageId
                )}
                startIcon={<ChatBubbleOutlineOutlinedIcon />}
              >
                {t("chatPrompt")}
              </Button>
            </Stack>
          )} */}
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Tabs value={currentTab} onChange={(_, tab: Tab) => setCurrentTab(tab)}>
          <Tab value={"INPUT"} label={t("input")} />
          <Tab value={"TOOLS"} label={t("tools")} />
          <Tab value={"OUTPUT"} label={t("output")} />
        </Tabs>

        <Stack mt={4}>
          {isLoading && <LoadingView />}
          {error && <ErrorView error={error} />}

          {chatPrompt && (
            <>
              {currentTab === "INPUT" && (
                <ChatPromptDialogTabInput chatPrompt={chatPrompt} />
              )}
              {currentTab === "TOOLS" && (
                <ChatPromptDialogTabTools chatPrompt={chatPrompt} />
              )}
              {currentTab === "OUTPUT" && (
                <ChatPromptDialogTabOutput chatPrompt={chatPrompt} />
              )}
            </>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button size="large" onClick={close} startIcon={<ArrowBackIcon />}>
          {t("goBack")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
