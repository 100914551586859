import { File as FileFromTypes, UploadFileDefinition, UploadFileResponse } from "@stai/types";
import { nanoid } from "nanoid";
import { useCallback, useState } from "react";
import { handleError } from "../helpers/handleError";
import { createCallable } from "@stai/common";
interface Params {
  projectId: string;
  ownerId: string;
  ownerType: FileFromTypes["ownerType"];
  onFileUploadStarted?: () => void;
  onFileUploaded?: (
    uploadedFile: UploadFileResponse,
    originalFile?: File
  ) => void;
}

const uploadFileCallable = createCallable(UploadFileDefinition);

export const useUploadFiles = (params: Params) => {
  const [isUploading, setIsUploading] = useState(false);
  const [errors, setErrors] = useState<Error[]>([]);

  const uploadFromText = useCallback(
    async (content: string, filename: string) => {
      const base64String = Buffer.from(content).toString("base64");
      const size = Buffer.byteLength(content, "utf8");

      const uploadedFile = await uploadFileCallable({
        projectId: params.projectId,
        ownerId: params.ownerId,
        ownerType: params.ownerType,
        file: {
          name: filename,
          mimeType: "text/plain",
          size: size,
          content: base64String,
        },
      });

      return uploadedFile;
    },
    [params.ownerId, params.ownerType]
  );

  const uploadSingleFile = useCallback(
    async (file: File) => {
      const arrayBuffer = await file.arrayBuffer();
      const base64String = Buffer.from(arrayBuffer).toString("base64");

      const response = await uploadFileCallable({
        projectId: params.projectId,
        ownerId: params.ownerId,
        ownerType: params.ownerType,
        file: {
          name: file.name,
          mimeType: file.type,
          size: file.size,
          content: base64String,
        },
      });

      return response;
    },
    [params.ownerId, params.ownerType]
  );

  const uploadFiles = useCallback(
    async (files: File[]) => {
      if (!files.length) return;
      setIsUploading(true);

      const results = await Promise.allSettled(
        files.map((file) => {
          params.onFileUploadStarted?.();
          return uploadSingleFile(file)
            .then((uploadedFile) => {
              params.onFileUploaded?.(uploadedFile, file);
            })
            .catch((error) => handleError(error));
        })
      );

      setIsUploading(false);

      const errors = results.reduce<Error[]>((acc, result) => {
        if (result.status === "fulfilled") return acc;
        if (!result.reason) return acc;

        if (result.reason instanceof Error) {
          acc.push(result.reason);
        } else {
          acc.push(new Error(result.reason as string));
        }

        return acc;
      }, []);

      if (!errors.length) return;
      setErrors(errors);
    },
    [uploadSingleFile, params]
  );

  const uploadText = useCallback(
    async (content: string) => {
      if (!content.length) return;
      setIsUploading(true);

      params.onFileUploadStarted?.();
      return await uploadFromText(content, nanoid())
        .then((uploadedFile) => {
          params.onFileUploaded?.(uploadedFile);
        })
        .catch((error) => {
          setErrors([error]);
        })
        .finally(() => {
          setIsUploading(false);
        });
    },
    [uploadFromText, params]
  );

  return [uploadFiles, uploadText, isUploading, errors] as const;
};
