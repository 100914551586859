import { zodResolver } from "@hookform/resolvers/zod";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { createCallable } from "@stai/common";
import {
  AddApiToolConfigDefinition,
  ApiToolConfig,
  DeleteApiToolConfigDefinition,
  TestApiToolDefinition,
  UpdateApiToolConfigDefinition,
} from "@stai/types";
import { useTranslation } from "next-i18next";
import { FC, useCallback, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../../Common/helpers/handleError";
import { ControlledSelect } from "../../../Common/components/forms/ControlledSelect";
import { ControlledSwitch } from "../../../Common/components/forms/ControlledSwitch";
import { ControlledTextField } from "../../../Common/components/forms/ControlledTextField";
import { ApiToolTestDialog } from "./ApiToolTestDialog";
import { FormValues } from "./FormValues";
import { useApiTestToolDialog } from "./hooks/useApiTestToolDialog";

const deleteApiToolConfigCallable = createCallable(
  DeleteApiToolConfigDefinition
);
const testApiToolConfigCallable = createCallable(TestApiToolDefinition);
const addApiToolConfigCallable = createCallable(AddApiToolConfigDefinition);
const updateApiToolConfigCallable = createCallable(
  UpdateApiToolConfigDefinition
);

interface Props {
  projectId: string;
  apiToolConfig: Partial<ApiToolConfig>;
}

function defaultValues(apiToolConfig: Partial<ApiToolConfig>) {
  return {
    name: apiToolConfig.name,
    description: apiToolConfig.description,
    method: apiToolConfig.method,
    bodyTemplate: apiToolConfig.bodyTemplate || "",
    parameters: apiToolConfig.parameters || [],
    headers: apiToolConfig.headers || [],
    responseTemplate: apiToolConfig.responseTemplate || "",
    urlTemplate: apiToolConfig.urlTemplate,
    errorMessage: apiToolConfig.errorMessage || "",
    enabled: apiToolConfig.enabled,
  };
}

export const ApiToolForm: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { projectId, apiToolConfig } = props;

  const isCreating = !apiToolConfig.id;

  const navigate = useNavigate();

  const apiToolTestDialog = useApiTestToolDialog();

  const { control, handleSubmit, formState, getValues, reset } =
    useForm<FormValues>({
      resolver: zodResolver(FormValues),
      defaultValues: defaultValues(apiToolConfig),
    });

  useEffect(() => {
    reset(defaultValues(apiToolConfig));
  }, [apiToolConfig]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (apiToolConfig.id) {
          await updateApiToolConfigCallable({
            apiToolConfigId: apiToolConfig.id,
            projectId: projectId,
            name: formValues.name,
            description: formValues.description,
            bodyTemplate: formValues.bodyTemplate,
            method: formValues.method,
            responseTemplate: formValues.responseTemplate,
            headers: formValues.headers,
            parameters: formValues.parameters,
            urlTemplate: formValues.urlTemplate,
            enabled: formValues.enabled,
            errorMessage: formValues.errorMessage,
          });
        } else {
          await addApiToolConfigCallable({
            projectId: projectId,
            name: formValues.name,
            description: formValues.description,
            bodyTemplate: formValues.bodyTemplate,
            method: formValues.method,
            responseTemplate: formValues.responseTemplate,
            headers: formValues.headers,
            parameters: formValues.parameters,
            urlTemplate: formValues.urlTemplate,
            enabled: formValues.enabled,
            errorMessage: formValues.errorMessage,
          }).then(({ apiToolConfigId }) => navigate(`../${apiToolConfigId}`));
        }
        reset(formValues);
        toast.success(t("saved"));
      } catch (error) {
        toast.success("Error!");
        handleError(error);
      }
    },
    [projectId, apiToolConfig]
  );

  const [isDeleting, setIsDeleting] = useState(false);
  const deleteApiTool = () => {
    if (!apiToolConfig.id) {
      return;
    }
    setIsDeleting(true);
    deleteApiToolConfigCallable({
      apiToolConfigId: apiToolConfig.id,
      projectId: projectId,
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const [isTesting, setIsTesting] = useState(false);
  const testApiTool = useCallback(async () => {
    if (!apiToolConfig.id) {
      return;
    }

    setIsTesting(true);
    try {
      const response = await testApiToolConfigCallable({
        apiToolConfigId: apiToolConfig.id,
        projectId: projectId,
        parameters: getValues().parameters.map((parameter) => ({
          name: parameter.name,
          value: parameter.testValue,
        })),
      });
      setIsTesting(false);
      apiToolTestDialog.open(
        apiToolConfig.id,
        response.response,
        response.error
      );
    } catch (e: any) {
      setIsTesting(false);
      apiToolTestDialog.open(
        apiToolConfig.id,
        undefined,
        `Request failed: ${e.message}`
      );
    }
  }, [apiToolConfig.id]);

  const parameters = useFieldArray({
    control,
    name: "parameters",
  });

  const headers = useFieldArray({
    control,
    name: "headers",
  });

  const isUpdating = formState.isSubmitting;
  const isValid = formState.isValid;

  return (
    <>
      <ApiToolTestDialog />
      <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack component={Paper} p={2} spacing={3}>
          <ControlledTextField
            control={control}
            label={t("apitool.form.functionName")}
            name={"name"}
            autoComplete="off"
            disabled={isUpdating}
          />
          <ControlledTextField
            control={control}
            label={t("apitool.form.functionDescription")}
            name="description"
            autoComplete="off"
            disabled={isUpdating}
          />
          <Stack direction="row" spacing={1.5} alignItems="center">
            <ControlledTextField
              control={control}
              label={t("apitool.form.urlTemplate")}
              name="urlTemplate"
              autoComplete="off"
              fullWidth
              disabled={isUpdating}
            />
            <ControlledSelect
              control={control}
              label={t("apitool.form.method")}
              name={"method"}
              sx={{ width: 150 }}
              items={["GET", "POST", "PUT", "PATCH", "DELETE"].map(
                (method) => ({
                  label: method,
                  value: method,
                })
              )}
              renderItem={(item) => item.label}
              // onChange={() => setValue("method", "")}
              disabled={isUpdating}
            />
          </Stack>
          <ControlledTextField
            control={control}
            label={t("apitool.form.bodyTemplate")}
            name="bodyTemplate"
            autoComplete="off"
            multiline
            rows={4}
            disabled={isUpdating}
          />

          <Stack spacing={1.5}>
            <Typography variant="ah3">{t("apitool.form.parameters")}</Typography>

            {parameters.fields.map((parameter, index) => (
              <Stack
                direction="row"
                key={parameter.id}
                spacing={1.5}
                pr={0}
                alignItems="center"
              >
                <ControlledTextField
                  sx={{ minWidth: 200 }}
                  control={control}
                  label={t("apitool.form.parameterName")}
                  name={`parameters.${index}.name`}
                  autoComplete="off"
                  disabled={isUpdating}
                />

                <ControlledTextField
                  control={control}
                  label={t("apitool.form.parameterDescription")}
                  name={`parameters.${index}.description`}
                  autoComplete="off"
                  disabled={isUpdating}
                  fullWidth
                />
                <ControlledTextField
                  sx={{ minWidth: 200 }}
                  control={control}
                  label={t("apitool.form.testValue")}
                  name={`parameters.${index}.testValue`}
                  autoComplete="off"
                  disabled={isUpdating}
                />
                <ControlledSelect
                  control={control}
                  label={t("apitool.form.parameterType")}
                  name={`parameters.${index}.type`}
                  sx={{ minWidth: 100, mr: 15 }}
                  items={["string", "number", "boolean"].map((paramType) => ({
                    label: paramType,
                    value: paramType,
                  }))}
                  renderItem={(item) => item.label}
                  // onChange={() => setValue("method", "")}
                  disabled={isUpdating}
                />
                <ControlledSwitch
                  control={control}
                  label={t("apitool.form.required")}
                  name={`parameters.${index}.required`}
                  disabled={isUpdating}
                />
                <Stack
                  sx={{ pl: 10 }}
                  direction="row"
                  spacing={1.5}
                  alignItems="center"
                >
                  <IconButton
                    onClick={() => parameters.remove(index)}
                    disabled={isUpdating}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Stack>
              </Stack>
            ))}
            <Stack direction="row" spacing={1.5} alignItems="center">
              <IconButton
                onClick={() =>
                  parameters.append({
                    name: `parameter${parameters.fields.length + 1}`,
                    type: "string",
                    description: `Function parameter ${
                      parameters.fields.length + 1
                    }`,
                    testValue: "",
                    required: true,
                  })
                }
                disabled={isUpdating}
              >
                <AddIcon fontSize="inherit" />
                <Typography variant="body2">{t("apitool.form.addParameter")}</Typography>
              </IconButton>
            </Stack>
          </Stack>
          <Stack spacing={1.5}>
            <Typography variant="ah3">{t("apitool.form.headers")}</Typography>

            {headers.fields.map((header, index) => (
              <Stack
                key={header.id}
                direction="row"
                spacing={1.5}
                alignItems="center"
              >
                <ControlledTextField
                  control={control}
                  label={t("apitool.form.headerName")}
                  name={`headers.${index}.name`}
                  autoComplete="off"
                  disabled={isUpdating}
                />
                <ControlledTextField
                  control={control}
                  label={t("apitool.form.headerValue")}
                  name={`headers.${index}.value`}
                  autoComplete="off"
                  disabled={isUpdating}
                  fullWidth
                />
                <Stack direction="row" spacing={1.5} alignItems="center">
                  <IconButton
                    onClick={() => headers.remove(index)}
                    disabled={isUpdating}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Stack>
              </Stack>
            ))}

            <Stack direction="row" spacing={1.5} alignItems="center">
              <IconButton
                onClick={() =>
                  headers.append({
                    name: `header${headers.fields.length + 1}`,
                    value: `Function header ${parameters.fields.length + 1}`,
                  })
                }
                disabled={isUpdating}
              >
                <AddIcon fontSize="inherit" />
                <Typography variant="body2">{t("apitool.form.addHeader")}</Typography>
              </IconButton>
            </Stack>
          </Stack>
          <ControlledTextField
            control={control}
            label={t("apitool.form.returnValueTemplate")}
            name="responseTemplate"
            autoComplete="off"
            multiline
            rows={4}
            disabled={isUpdating}
          />
          <ControlledTextField
            control={control}
            label={t("apitool.form.errorMessage")}
            name="errorMessage"
            autoComplete="off"
            multiline
            rows={4}
            disabled={isUpdating}
          />

          <Stack
            direction="row"
            spacing={6}
            // alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              width="100%"
              spacing={3}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" spacing={4}>
                <Button
                  size="large"
                  variant="contained"
                  sx={{ alignSelf: "flex-start" }}
                  disabled={isUpdating || !isValid}
                  onClick={handleSubmit(onSubmit)}
                >
                  {isCreating ? t("save") : t("update")}
                </Button>
                <Button
                  size="large"
                  variant="outlined"
                  sx={{ alignSelf: "flex-start" }}
                  disabled={isTesting}
                  onClick={testApiTool}
                >
                  {t("apitool.form.testApiTool")}
                </Button>
              </Stack>
              {isDeleting && <CircularProgress />}
              {!isDeleting && !isCreating && (
                <Tooltip title={t("apitool.form.deleteApiTool")} placement="top">
                  <IconButton size="large" onClick={deleteApiTool}>
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
